'use strict';
import React from 'react';
import { observer } from 'mobx-react';

import { TextField, Button } from '@material-ui/core/';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ReactTable from 'react-table';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
import './table.css';
import { Utils } from '../../services';
import AutoSelect from '../../components/inputs/AutoSelect';
import useTimeSheets from './useTimesheets';
import useMediaQuery from '../../hooks/useMediaQuery';

const styles = {
  rszSelect: {
    width: '13em',
  },
};

const Timesheet = ({ classes }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    missingHours,
    isMasterUser,
    userList,
    professionalHandleChange,
    professionalError,
    professional,
    loadScreen,
    selectedDate,
    dateError,
    setSelectedDate,
    getMissingTime,
    project,
    projectError,
    projectList,
    hours,
    hoursErro,
    missingHourList,
    validaFields,
    timesheet,
    deleteAppointment,
    setProject,
    setHours,
  } = useTimeSheets();

  return (
    <div id="Timesheet">
      <br />
      <div>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="flex-end"
        >
          <h2>
            {missingHours === '0'
              ? 'Você já apontou todas as suas horas hoje.'
              : 'Você ainda tem ' +
              (missingHours < 2
                ? missingHours + ' hora para apontar hoje.'
                : missingHours + ' horas para apontar hoje.')}
          </h2>
        </Grid>
      </div>
      <div>
        <br />
        <Paper
          style={{
            ...(!isMobile && { width: 880 }),
            margin: 'auto',
            padding: '2em',
          }}
        >
          <Grid
            container
            direction={!isMobile && 'row'}
            justify={!isMobile && 'center'}
            spacing={24}
          >
            <Grid item>
              {isMasterUser && (
                <FormControl style={{ width: '300px', marginTop: '2px' }}>
                  <AutoSelect
                    suggestions={userList}
                    handleChange2={professionalHandleChange}
                    placeholder="Profissional"
                    error={professionalError}
                    initialValue={professional}
                    slice={true}
                    callback={loadScreen}
                  />
                </FormControl>
              )}

              {/*<FormControl className={classes.formControl} style={{}}>*/}
              {/*<InputLabel htmlFor="professional">Profissional</InputLabel>*/}
              {/*<Select*/}
              {/*className={classes.rszSelect}*/}
              {/*value={professional}*/}
              {/*inputProps={{*/}
              {/*name: 'professional',*/}
              {/*id: 'professional',*/}
              {/*}}*/}
              {/*error={professionalError}*/}
              {/*onChange={professionalHandleChange}*/}
              {/*>*/}

              {/*{userList.map((item) => (*/}
              {/*<MenuItem value={item.cpf_usuario}>{item.nome_usuario}</MenuItem>*/}
              {/*))}*/}

              {/*</Select>*/}
              {/*</FormControl>*/}
            </Grid>

            <Grid item>
              {isMasterUser && (
                <FormControl style={{ width: '140px' }}>
                  <TextField
                    id="date"
                    type="date"
                    className={classes.textField}
                    value={selectedDate}
                    error={dateError}
                    style={{ paddingTop: '16px' }}
                    onChange={(e) => {
                      setSelectedDate(e.target.value);
                      getMissingTime();
                    }}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="project">Projeto</InputLabel>
                <Select
                  className={classes.rszSelect}
                  value={project}
                  inputProps={{
                    name: 'project',
                    id: 'project',
                  }}
                  error={projectError}
                  onChange={(e) => setProject(e.target.value)}
                >
                  {projectList.map((item) => (
                    <MenuItem key={item.id_projeto} value={item.id_projeto}>
                      {item.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {console.log('missingHourList', missingHourList)}
            <Grid item>
              <FormControl style={{ width: '90px' }}>
                <InputLabel htmlFor="hours">Qtd. horas</InputLabel>
                <Select
                  inputProps={{
                    name: 'hours',
                    id: 'hours',
                  }}
                  value={hours}
                  error={hoursErro}
                  //   defaultChecked={missingHours}
                  onChange={(e) => setHours(e.target.value)}
                >
                  {missingHourList.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <br />
          <br />
          <br />
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => validaFields()}
            >
              Lançar
            </Button>
          </Grid>
          <br />
        </Paper>
        <br />
      </div>
      <div>
        <br />
        <div style={{ margin: 'auto', ...(!isMobile && { width: 880 }) }}>
          <span style={{ fontSize: 18, marginBottom: 8 }}>
            Últimos Apontamentos
          </span>
        </div>

        <br />
        <div
          style={{
            ...(!isMobile && { width: 880 }),
            margin: 'auto',
          }}
        >
          <ReactTable
            filterable
            data={timesheet}
            defaultPageSize={20}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              filter.id === 'data'
                ? Utils.dateToPT(String(row[filter.id])).startsWith(
                  filter.value.toLowerCase()
                )
                : String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
            }
            columns={[
              {
                Header: 'Profissional',
                accessor: 'nome_usuario',
                show: isMasterUser,
              },
              {
                Header: 'Projeto',
                accessor: 'desc_curta',
              },
              {
                Header: 'Tipo projeto',
                accessor: 'tipo_projeto',
                maxWidth: 140,
              },
              {
                Header: 'Data',
                accessor: 'data',
                width: 100,
                Cell: (row) => (
                  <div className="action-cell" style={{ padding: '0px' }}>
                    {Utils.dateToPT(row.value)}
                  </div>
                ),
              },
              {
                Header: 'qtd. hora',
                accessor: 'qtd_horas',
                width: 80,
                Cell: (row) => (
                  <div
                    className="action-cell"
                    style={{ padding: '0px', textAlign: 'center' }}
                  >
                    {row.value + (row.value >= 2 ? ' Horas' : ' Hora')}
                  </div>
                ),
              },
              {
                Header: 'Ação',
                accessor: 'actions',
                width: 60,
                filterable: false,
                Cell: (row) => (
                  <div className="action-cell" style={{ padding: '0px' }}>
                    <DeleteIcon
                      onClick={() =>
                        deleteAppointment(
                          row.original['id_projeto'],
                          row.original['cpf_usuario'],
                          row.original['timestamp_apontamento']
                        )
                      }
                      style={{
                        cursor: 'pointer',
                        width: '22px',
                        height: '22px',
                        minWidth: '22px',
                        minHeight: '22px',
                        marginLeft: '5px',
                      }}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(withStyles(styles)(Timesheet));
