import React from "react";
import ReactTable from "react-table";
import {FormControl, Grid, TextField, Button, Select, MenuItem, Paper} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { Utils } from "../../../services";
import AppState from "../../../state";
import { browserHistory } from "react-router";
import debounce from 'lodash.debounce';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteIcon from "@material-ui/icons/DeleteForever";
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Modal from "../../../components/lib/Modal";
import ImportButton from "../../../components/lib/ImportButton";
import conciliacaoUtils from "./Utils";
import CurrencyInput from "../../../components/lib/CurrencyInput";
import MaskedInput from "react-text-mask";
import AutoSelect from '../../../components/inputs/AutoSelect';

const DateMaskCustom = (props) => {
  const {inputRef, ...other} = props;

  return (
      <MaskedInput
          {...other}
          // ref={inputRef}
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          guide={false}
      />
  );
}

const bradesco = '237 Banco Bradesco S.A.';
const itau = '341 Itaú Unibanco S.A.';
const santander = '033 Banco Santander (Brasil) S.A.';

class Conciliacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      period: "Ontem",
      dtInicio: "",
      dtFim: "",
      pagamentosList: [],
      lancamentosBancariosList: [],
      conciliacoesList: [],
      selectedPgtoRecb: '',
      selectedLancamento: '',
      conciliacao: false,
      checked: [],
      selectAll: false,
      id_conciliacao_delete: '',
      id_lancamento_delete: '',
      dialogConfirmationDelete: false,
      bankFilter: "",
      description: "",
      dialogConfirmationConciliation: false,
      bankTotal: 0,
      ambgestTotal: 0,
      dialogPaymentDetails: false,
      detailedPayment: "",
      detailedPaymentData: "",
      account: "",
      accountList: [],
      current_or_not_current: true,
      director: ""
    };

    this.lancamentosTable = React.createRef();
    this.pagsRecbsTable = React.createRef();
    this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
  }

  componentDidMount () {
    AppState.isLoading = true;
    this.getContas();
  }

  getData() {
    if ((this.state.bankFilter !== bradesco && this.state.bankFilter !== "") ||
        (this.state.bankFilter == bradesco && this.state.current_or_not_current == true) ||
        (this.state.bankFilter == bradesco && this.state.current_or_not_current == false && this.state.director !== "") ) {
      if (this.state.period !== "custom" || (this.state.period == "custom" && this.state.dtInicio !== "" && this.state.dtFim !== "")) {
        AppState.isLoading = true;
        this.getPgtosRecbs();
        this.getLancamentosBancarios();
        this.getConciliacoes();
      }
    }
  }

  _debouncedHandleChange = () => {
    this.getData();
  }

  getContas = function () {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/GetContas.php`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization':AppState.token,
        }
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
            const res = response.json()
            return res;
        }
    })
    .then((response) => {
        this.setState({
            accountList: response.request.map(suggestion => ({
              value: suggestion.id_conta,
              label: suggestion.nome_display,
          }))
        }, () => { 
            AppState.isLoading = false;
            });
        })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
              console.log("40001 - Dados inválidos");
              alert("Dados inválidos");
            }
            else
              alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }

  getPgtosRecbs = function () {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/GetLancamentos.php?json={"periodo":"${this.state.period}", "dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}", "banco":"${this.state.bankFilter}", "id_conta":"${this.state.account}", "eh_corrente":"${this.state.current_or_not_current}", "diretor":"${this.state.director}"}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization':AppState.token,
        }
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
            const res = response.json()
            return res;
        }
    })
    .then((response) => {
        this.setState({
            pagamentosList: response.request
        }, () => { 
            AppState.isLoading = false;
            });
        })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
              console.log("40001 - Datas de inicio e fim não enviadas");
              alert("Datas de inicio e fim não enviadas");
            } if(ret == '40002') {
              console.log("40002 - Período não enviado");
              alert("Período não enviado");
            } if(ret == '40004') {
              console.log("40004 - Banco não enviado");
              alert("Banco não enviado");
            }
            else
                alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }

  getLancamentosBancarios = function () {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/GetLancamentosBancarios.php?json={"periodo":"${this.state.period}", "dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}", "banco":"${this.state.bankFilter}", "eh_corrente":"${this.state.current_or_not_current}", "diretor":"${this.state.director}"}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization':AppState.token,
        }
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
            const res = response.json()
            return res;
        }
    })
    .then((response) => {
        this.setState({
            lancamentosBancariosList: response.request
        }, () => { 
            AppState.isLoading = false;
            });
        })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
              console.log("40001 - Datas de inicio e fim não enviadas");
              alert("Datas de inicio e fim não enviadas");
            } if(ret == '40002') {
              console.log("40002 - Período não enviado");
              alert("Período não enviado");
            } if(ret == '40004') {
              console.log("40004 - Banco não enviado");
              alert("Banco não enviado");
            }
            else
                alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }

  getConciliacoes = function () {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/GetConciliacoes.php?json={"periodo":"${this.state.period}", "dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}", "banco":"${this.state.bankFilter}", "eh_corrente":"${this.state.current_or_not_current}", "diretor":"${this.state.director}"}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization':AppState.token,
        }
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
            const res = response.json()
            return res;
        }
    })
    .then((response) => {
        this.setState({
            conciliacoesList: response.request
        }, () => { 
            AppState.isLoading = false;
            });
        })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
              console.log("40001 - Datas de inicio e fim não enviadas");
              alert("Datas de inicio e fim não enviadas");
            } if(ret == '40002') {
              console.log("40002 - Período não enviado");
              alert("Período não enviado");
            } if(ret == '40004') {
              console.log("40004 - Banco não enviado");
              alert("Banco não enviado");
            }
            else
                alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }


  periodHandleChange = event => {
    this.setState(
      {
        dtInicio: "",
        dtFim: "",
        period: event.target.value
      },
      () => {
        if (this.state.period != "custom") {
          this.getData()
        }
      }
    );
  };

  dateHandleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        if (this.state.dtInicio.length === 10 && this.state.dtFim.length === 10)
        this._debouncedHandleChange();
      }
    );
  };

  singleCheckboxHandleChange = (index) => {
    let checkedCopy = this.state.checked;
    checkedCopy[index] = !this.state.checked[index];
    if (checkedCopy[index] === false) {
        this.setState({ selectAll: false });
    }

    this.setState({
        checked: checkedCopy
    });
}

  selectAllLancamentosHandleChange = (arr) => {
    let selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });

    let checkedCopy = [];
    this.state.lancamentosBancariosList.forEach((e, index) => {
        checkedCopy.push(false);
    });

    let table = this.lancamentosTable.current.getResolvedState().sortedData;
    table.forEach((e, index) => {
        checkedCopy[e._index] = selectAll;
    });
    
    this.setState({
        checked: checkedCopy
    });
  };

  selectAllPagsRecbsHandleChange = (arr) => {
    let selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });

    let checkedCopy = [];
    this.state.pagamentosList.forEach((e, index) => {
        checkedCopy.push(false);
    });

    let table = this.pagsRecbsTable.current.getResolvedState().sortedData;
    table.forEach((e, index) => {
        checkedCopy[e._index] = selectAll;
    });
    
    this.setState({
        checked: checkedCopy
    });
  };

  hasConciliationPermission = () => {
    const permissions = JSON.parse(localStorage.getItem("Menu"));
    const aprovacaoView = permissions.views
      .filter(view => view.id_view === "conciliacao")
      .pop();

    const hasApprovePermission = aprovacaoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === "conciliar";
      },
      false
    );

    return hasApprovePermission;
  };

  hasImportPermission = () => {
    const permissions = JSON.parse(localStorage.getItem("Menu"));
    const aprovacaoView = permissions.views
      .filter(view => view.id_view === "conciliacao")
      .pop();

    const hasApprovePermission = aprovacaoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === "lancar_extrato";
      },
      false
    );

    return hasApprovePermission;
  };

  confirmaConciliacao = () => {
    if (!this.state.conciliacao) {
      return
    }

    let id_pagamentos, pagamentos_total;
    let id_lancamentos, lancamentos_total;
    let check_bancos, banco_ref
    
    if (this.state.selectedPgtoRecb == '') {
      let table = this.pagsRecbsTable.current.getResolvedState().sortedData;
      let selected = table.filter((el, index) => {
          if (this.state.checked[el._index] == true) {
              return true
          }
          return false
      });
      
      id_pagamentos = selected.map((el) => {return el.id_lancamento});
      id_lancamentos = [this.state.selectedLancamento]
      
      if (selected.length == 0) {
        pagamentos_total = 0
      } else {
        pagamentos_total = selected.map(item => parseFloat(item.valor)).reduce((total, value) => {return total + value})
      }
      lancamentos_total = this.state.lancamentosBancariosList.filter((el) => el.id_lancamento == this.state.selectedLancamento).map(item => parseFloat(item.valor)).reduce((total, el) => total + el.valor)

      if(lancamentos_total !== pagamentos_total && this.state.description == "") {
        this.setState({
          bankTotal: lancamentos_total,
          ambgestTotal: pagamentos_total,
          dialogConfirmationConciliation: true
        })
        return
      }
    } else {
      let table = this.lancamentosTable.current.getResolvedState().sortedData;
      let selected = table.filter((el, index) => {
          if (this.state.checked[el._index] == true) {
              return true
          }
          return false
      });
  
      id_lancamentos = selected.map((el) => {return el.id_lancamento});
      id_pagamentos = [this.state.selectedPgtoRecb]
      
      if (selected.length == 0) {
        lancamentos_total = 0;
      } else {
        lancamentos_total = selected.map(item => parseFloat(item.valor)).reduce((total, value) => {return total + value})
      }
      pagamentos_total = this.state.pagamentosList.filter((el) => el.id_lancamento == this.state.selectedPgtoRecb).map(item => parseFloat(item.valor)).reduce((total, el) => total + el.valor)

      if(lancamentos_total - pagamentos_total > 0.1 && this.state.description == "") {
        this.setState({
          bankTotal: lancamentos_total,
          ambgestTotal: pagamentos_total,
          dialogConfirmationConciliation: true
        })
        return
      }
    }

    const data = JSON.stringify({
      id_lancamentos: id_lancamentos,
      id_pagamentos: id_pagamentos,
      descricao: this.state.description
    })

    AppState.isLoading = true
    fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/AddConciliacao.php`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization':AppState.token
      },
      body: data
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
          const res = response.json()
          this.setState({
            selectedPgtoRecb: '',
            selectedLancamento: '',
            description: "",
            conciliacao: false,
            selectAll: false,
            checked: [],
            dialogConfirmationConciliation: false
          });
          return res;
        }
    })
    .then((response) => {
        this.getData();
    })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
                console.log("40001 - Dados inválidos");
                alert("Dados inválidos");
            } else if(ret == '40002') {
                console.log("40002 - Seleção incorreta");
                alert("Seleção incorreta. Por favor verifique titulos rateados.");
            } else if(ret == '40003') {
                console.log("40003 - Erro na conciliação");
                alert("Erro na conciliação");
            } else alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }

  removeConciliacao = () => {
    const data = JSON.stringify({
      id_conciliacao: this.state.id_conciliacao_delete
    })

    AppState.isLoading = true
    fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/DeleteConciliacao.php`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization':AppState.token
      },
      body: data
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
          const res = response.json()
          this.setState({
            id_conciliacao_delete: '',
            dialogConfirmationDelete: false
          });
          return res;
        }
    })
    .then((response) => {
        this.getData();
    })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
                console.log("40001 - Dados inválidos");
                alert("Dados inválidos");
            } else if(ret == '40002') {
                console.log("40002 - Id Lançamento não enviado");
                alert("Erro no envio dos dados.");
            } else alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }

  filtroBancoHandleChange = (event) => {
    this.setState({
      bankFilter: event.target.value,
    }, () => this.getData())
  }

  removeLancamentoBancario = () => {
    const data = JSON.stringify({
      id_lancamento: this.state.id_lancamento_delete
    })

    AppState.isLoading = true
    fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/DeleteLancamentoBancario.php`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization':AppState.token
      },
      body: data
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
          const res = response.json()
          this.setState({
            id_lancamento_delete: '',
            dialogConfirmationDelete: false
          });
          return res;
        }
    })
    .then((response) => {
        this.getData();
    })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
                console.log("40001 - Dados inválidos");
                alert("Dados inválidos");
            } else if(ret == '40002') {
                console.log("40002 - Id Lançamento não enviado");
                alert("Erro no envio dos dados.");
            } else alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }

  importarDados = (ev) => {
    const input = ev.target;
    const file = input.files[0];
    let data = [];
    const bank = this.state.bankFilter;

    var reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      if (!text) {
        return
      }

      try {
        if ( bank == bradesco) {
          if (this.state.current_or_not_current == true) {
            data = conciliacaoUtils.bradescoPreProcess(text, bank, this.state.current_or_not_current, this.state.director);
          } else if (this.state.director !== "") {
            data = conciliacaoUtils.bradescoPreProcess(text, bank, this.state.current_or_not_current, this.state.director);
          } else {
            alert("Por favor selecione as opções necessárias.")
            AppState.isLoading = false;
            return
          }
        } else if (bank == itau) {
          data = conciliacaoUtils.itauPreProcess(text, bank);
        } else if (bank == santander) {
          data = conciliacaoUtils.santanderPreProcess(text, bank);
        } else {
          alert('Por favor selecione um banco válido')
          AppState.isLoading = false;
          return
        }
      }
      catch (e) {
        alert('O arquivo está num formato não previsto. Por favor verifique se o arquivo está correto.')
        AppState.isLoading = false;
        return
      }
 
      fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/AddLancamentoBancario.php`, {
        method: "POST",
        headers: {
          Authorization: AppState.token
        },
        body: JSON.stringify(data)
      }).then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json()
          this.setState({
            dialogImport: false,
            bank: ""
          });
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.getData();
      })
      .catch((error) => {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
                console.log("40001 - Dados inválidos");
                alert("Dados inválidos");
            } else if(ret == '40002') {
                console.log("40002 - Nada a ser importado");
                alert("Não há dados a serem importados.");
            } else if(ret == '40003') {
                console.log("40003 - Erro ao inserir lançamento bancário");
                alert("Erro no envio dos dados.");
            } else if(ret == '40004') {
                console.log("40004 - Erro ao inserir lançamento bancário");
                alert(error.response['status'].value);
            } else alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
        this.setState({
          dialogImport: false,
          bank: ""
        });
      })
    };
    if (file) {
      AppState.isLoading = true;
      reader.readAsText(file);
    }

    ev.target.value = null;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleChangeAndUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    }, () => this.getData())
  }

  currentHandleChange = (event) => {
    if (event.target.value == false) {
      this.setState({
        [event.target.name]: event.target.value,
        director: ""
      }, () => this.getData())
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      }, () => this.getData())
    }
  }

  getPaymentDetails = () => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/conciliacao/GetPgtoRecebDetalhe.php?json={"id_pgtoReceb":"${this.state.detailedPayment}"}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization':AppState.token,
        }
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((json) => {
                let error = new Error(response.status);
                error.response = json;
                throw error;
            });
        } else {
            const res = response.json()
            return res;
        }
    })
    .then((response) => {
        this.setState({
            detailedPaymentData: response.request[0],
            dialogPaymentDetails: true
        }, () => { 
            AppState.isLoading = false;
            });
        })
    .catch(function(error) {
        AppState.isLoading = false;
        if(error.toString() == "" || error.toString().split(' ').length < 2){
            alert("Erro desconhecido, tente novamente");
            return ;
        }
        var Err_status = error.toString().split(' ')[1];
        if(Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if(ret == '40001') {
              console.log("40001 - Datas de inicio e fim não enviadas");
              alert("Datas de inicio e fim não enviadas");
            } if(ret == '40002') {
              console.log("40002 - Período não enviado");
              alert("Período não enviado");
            } if(ret == '40004') {
              console.log("40004 - Banco não enviado");
              alert("Banco não enviado");
            }
            else
                alert("Dados inválidos");
        }
        else if(Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        }
        else if(Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
        }
    })
  }
  
  switchContraparte = function(tipo_contraparte) {
    switch (tipo_contraparte) {
        case "funcionario": return "Funcionário";
        case "pj": return "Pessoa Jurídica (PJ)";
        case "fornecedor": return "Fornecedor";
        case "cliente": return "Cliente";
        case "outros": return "Outros";
    }
  }

  switchNatureza = function(natureza) {
    switch (natureza) {
        case "despesa": return "à Pagar";
        case "receita": return "à Receber";
        case "controle": return "Controle";
    }
  }

  accountHandleChange = (value) => {
    this.setState({
      account: value ? value.value : '',
    }, () => {
      this.getData();
    })
  }

  render() {
    const hasImportPermission = this.hasImportPermission();
    const hasConciliationPermission = this.hasConciliationPermission();
    return (
      <div id={this.constructor.name} style={{ marginTop: "5em" }}>
        <h1>Conciliação</h1>

        <Grid
          container
          justify="space-between"
          style={{ marginTop: "16px", marginBottom: "8px" }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={9}>
                <FormControl
                  component="fieldset"
                  style={{ width: "200px", paddingTop: "15px", marginLeft: "15px" }}
                >
                  <InputLabel htmlFor={"bank"}>
                    {" "}
                    Banco{" "}
                  </InputLabel>
                  <Select
                    inputProps={{ id: "bank", name: "bank" }}
                    style={{ margin: "0.5em 0px" }}
                    value={this.state.bankFilter}
                    onChange={this.filtroBancoHandleChange}
                  >
                    <MenuItem value={"237 Banco Bradesco S.A."}>Banco Bradesco S.A.</MenuItem>
                    <MenuItem value={"341 Itaú Unibanco S.A."}>Itaú Unibanco S.A.</MenuItem>
                    <MenuItem value={"033 Banco Santander (Brasil) S.A."}>Banco Santander (Brasil) S.A.</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  component="fieldset"
                  style={{ width: "200px", paddingTop: "15px", marginLeft: "15px" }}
                >
                  <InputLabel htmlFor={"period"}>
                    {" "}
                    Período de lançamento{" "}
                  </InputLabel>
                  <Select
                    inputProps={{ id: "period", name: "period" }}
                    style={{ margin: "0.5em 0px" }}
                    value={this.state.period || "Ontem"}
                    onChange={this.periodHandleChange}
                  >
                    <MenuItem value={"Hoje"}>Hoje</MenuItem>
                    <MenuItem value={"Ontem"}>Ontem</MenuItem>
                    <MenuItem value={"5dias"}>Últimos 5 dias</MenuItem>
                    <MenuItem value={"EsseMes"}>Esse Mês</MenuItem>
                    <MenuItem value={"30dias"}>Últimos 30 dias</MenuItem>
                    <MenuItem value={"60dias"}>Últimos 60 dias</MenuItem>
                    <MenuItem value={"90dias"}>Últimos 90 dias</MenuItem>
                    <MenuItem value={"custom"}>Customizado</MenuItem>
                  </Select>
                </FormControl>

                {this.state.period === "custom" && (
                  <TextField
                    style={{
                      margin: "0px 16px 8px 32px",
                      paddingTop: "5px"
                    }}
                    label="Inicio"
                    type="date"
                    defaultValue={this.state.dt_inicio}
                    onChange={this.dateHandleChange("dtInicio")}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}

                {this.state.period === "custom" && (
                  <TextField
                    style={{ paddingTop: "5px" }}
                    label="Fim"
                    type="date"
                    defaultValue={this.state.dt_fim}
                    onChange={this.dateHandleChange("dtFim")}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                {this.state.bankFilter === bradesco ?
                  <FormControl
                    component="fieldset"
                    style={{ width: "150px", paddingTop: "15px", marginLeft: "15px" }}
                  >
                    <InputLabel htmlFor={"current_or_not_current"}>
                      É Corrente
                    </InputLabel>
                    <Select
                      inputProps={{ id: "current_or_not_current", name: "current_or_not_current" }}
                      style={{ margin: "0.5em 0px" }}
                      value={this.state.current_or_not_current}
                      onChange={this.currentHandleChange}
                    >
                      <MenuItem value={true}>Corrente</MenuItem>
                      <MenuItem value={false}>Não Corrente</MenuItem>
                    </Select>
                  </FormControl>
                : ""}
                {this.state.bankFilter === bradesco && this.state.current_or_not_current == false ?
                  <FormControl
                    component="fieldset"
                    style={{ width: "150px", paddingTop: "15px", marginLeft: "15px" }}
                  >
                    <InputLabel htmlFor={"director"}>
                      Diretor
                    </InputLabel>
                    <Select
                      inputProps={{ id: "director", name: "director" }}
                      style={{ margin: "0.5em 0px" }}
                      value={this.state.director}
                      onChange={this.handleChangeAndUpdate}
                    >
                      <MenuItem value={"339.109.107-00"}>JOSÉ EDUARDO RAMALHO ORTIGÃO</MenuItem>
                      <MenuItem value={"236.230.557-00"}>NEWTON BAPTISTA FERRAZ</MenuItem>
                      <MenuItem value={"049.978.498-71"}>CÉSAR MURILO DE MACAU</MenuItem>
                    </Select>
                  </FormControl>
                : ""}
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  component="fieldset"
                  style={{ width: "200px", paddingTop: "15px", marginLeft: "15px" }}
                >
                  <AutoSelect
                      value={this.state.account}
                      suggestions={this.state.accountList}
                      handleChange2={this.accountHandleChange}
                      placeholder='C. Contábil'
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-end"
            style={{margin:'auto'}}
            >
              <h2>
                  Não Conciliados
              </h2>
              <Grid container direction="row" justify="space-around" style={{marginTop: "1em", margin: "auto"}}>
                <Grid>
                  <Grid container direction="row" justify="space-around" alignItems="flex-end" style={{marginTop: "1em", margin: "auto"}}>
                    <h4>Lançamentos Bancários</h4>
                    <div style={{
                        display: 'inline-block',
                        width: 100,
                        textAlign: 'end',
                        alignSelf: 'normal'
                    }}>
                        {hasImportPermission && this.state.bankFilter !== "" ? (
                          <ImportButton
                            importButtonText={"Importar"}
                            onImport={this.importarDados}
                            fileType={'txt'}
                          />
                        ) : ""}
                    </div>
                  </Grid>
                  <ReactTable
                    filterable
                    ref={this.lancamentosTable}
                    data={ 
                      this.state.lancamentosBancariosList.sort((a, b) => {
                        var a1 = new Date(a.data).getTime();
                        var b1 = new Date(b.data).getTime();
                        if (a1 < b1) {
                            return -1;
                        } else if(a1 > b1) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                  }
                    defaultPageSize={10}
                    className="-striped -highlight"
                    defaultFilterMethod={(filter, row) =>
                        (filter.id === 'data')?(
                            Utils.dateToPT(String(row[filter.id])).startsWith(filter.value.toLowerCase())
                        ):(
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        )
                    }
                    columns={[
                            {
                                Header: "Nº",
                                accessor: "id_lancamento",
                                width: 50
                            }, {
                                Header: "Descrição",
                                accessor: "descricao",
                                width: 130
                            }, {
                                Header: "Data",
                                id: 'data',
                                accessor: 'data',
                                width: 90,
                                sortMethod: (a, b) => {
                                    var a1 = new Date(a).getTime();
                                    var b1 = new Date(b).getTime();
                                    if (a1 < b1) {
                                        return 1;
                                    } else if(a1 > b1) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                },
                                Cell: row => (
                                    Utils.dateToPT(row.value)
                                )
                            }, 
                            {
                                Header: "Valor",
                                accessor: "valor",
                                width: 110,
                                sortMethod: (a, b) => {
                                    a = parseFloat(a);
                                    b = parseFloat(b);
                                    if (a < b) {
                                        return -1;
                                    } else if(a > b) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                },
                                Footer: row => (
                                <span>
                                    {// Get the total of the price
                                    "R$ " +
                                    Utils.float2moeda(
                                        row.data.reduce(
                                        (total, { valor }) => (total += parseFloat(valor)),
                                        0
                                        )
                                    )}
                                </span>
                                ),
                                Cell: row => (
                                <div
                                    style={{
                                    height: "22px",
                                    verticalAlign: "middle",
                                    position: "relative"
                                    }}
                                >
                                    {"R$ " + Utils.float2moeda(row.value)}
                                </div>
                                )
                            }, {
                              Header: "Ação",
                              accessor: "acao",
                              filterable: false,
                              width: 70,
                              Header: row => (this.state.conciliacao == true && this.state.selectedPgtoRecb != '') ? (
                                <input
                                    type="checkbox"
                                    onChange={this.selectAllLancamentosHandleChange}
                                    checked={this.state.selectAll}
                                />
                              ) : (
                                ""
                              ),
                              Footer: row => (
                                <span>
                                    {// Get the total of the price
                                    this.state.checked.filter((el) => el).length > 1 && this.state.selectedPgtoRecb !== '' ?
                                    "R$ " +
                                    Utils.float2moeda(
                                        row.data.reduce(
                                        (total, { valor }, index) => 
                                          (total += this.state.checked[index] && this.state.selectedPgtoRecb !== '' ? 
                                                    parseFloat(valor) : 0)
                                        ,
                                        0
                                        )
                                    ) : ""}
                                </span>
                                ),
                              Cell: row =>
                              <div
                                style={{
                                  height: "22px",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  position: "relative"
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-around"
                                  alignItems="baseline"
                                >
                                  {
                                  (
                                    // Se não estou conciliando nada ou estou conciliando esse lancamento
                                    hasConciliationPermission && (this.state.conciliacao == false ||
                                    this.state.selectedLancamento == row['original'].id_lancamento) 
                                  )
                                  ? (
                                  <CachedIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={event => {
                                      if (this.state.conciliacao == true) {
                                        this.setState( {
                                            selectedLancamento: '',
                                            conciliacao: false,
                                            selectAll: false,
                                            checked: []
                                          })
                                      } else {
                                        this.setState( {
                                          selectedLancamento: row['original'].id_lancamento,
                                          conciliacao: true
                                        }) 
                                      }
                                    }}
                                  />
                                  ) : (
                                      ""
                                  )}
                                  {
                                  (
                                    // Se estou conciliando e parti pelo pagamento/receb
                                    (this.state.conciliacao == true && this.state.selectedPgtoRecb != '') 
                                  )
                                  ? (
                                  <div style={{textAlign:'center'}}>
                                      <input
                                          type="checkbox"
                                          checked={this.state.checked[row.index]}
                                          onChange={() => this.singleCheckboxHandleChange(row.index)}
                                      />
                                  </div>
                                  ) : (
                                      ""
                                  )}
                                  {hasImportPermission &&
                                  this.state.conciliacao == false ? (
                                  <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={event =>
                                      this.setState( {
                                        id_lancamento_delete: row['original'].id_lancamento,
                                        dialogConfirmationDelete: true
                                      })
                                    }
                                  />
                                  ) : (
                                      ""
                                  )}
                                </Grid>
                              </div>
                            }
                            ]
                        }
                  />
                </Grid>
                <Grid>
                <Grid container direction="row" justify="space-around" alignItems="flex-end" style={{marginTop: "1em", margin: "auto"}}>
                    <h4>Pagamentos / Recebimentos</h4>
                    <div style={{
                        display: 'inline-block',
                        width: 100,
                        textAlign: 'end',
                        alignSelf: 'normal'
                    }}>
                        <Button color="primary"
                        variant="contained"
                        onClick={this.confirmaConciliacao}
                        style={{display: this.state.conciliacao == true ? 'block' : 'none'}}
                        >
                          Confirmar
                        </Button>
                    </div>
                  </Grid>
                  <ReactTable
                    filterable
                    ref={this.pagsRecbsTable}
                    data={
                      this.state.pagamentosList.sort((a, b) => {
                        var a1 = new Date(a.data).getTime();
                        var b1 = new Date(b.data).getTime();
                        if (a1 < b1) {
                            return -1;
                        } else if(a1 > b1) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                  }
                    defaultPageSize={10}
                    className="-striped -highlight"
                    defaultFilterMethod={(filter, row) =>
                        (filter.id === 'data')?(
                            Utils.dateToPT(String(row[filter.id])).startsWith(filter.value.toLowerCase())
                        ):(
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        )
                    }
                    columns={[
                            {
                                Header: "Nº",
                                accessor: "id_lancamento",
                                width: 70
                            },
                            {
                                Header: "Data",
                                id: 'data',
                                accessor: 'data',
                                width: 90,
                                sortMethod: (a, b) => {
                                    var a1 = new Date(a).getTime();
                                    var b1 = new Date(b).getTime();
                                    if (a1 < b1) {
                                        return 1;
                                    } else if(a1 > b1) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                },
                                Cell: row => (
                                    Utils.dateToPT(row.value)
                                )
                            }, {
                                Header: "Descrição",
                                id: 'descricao',
                                accessor: 'descricao',
                                width: 170
                            }, {
                                Header: "CPF/CNPJ",
                                id: 'cpf_cnpj_contraparte',
                                accessor: 'cpf_cnpj_contraparte',
                                width: 120
                            },
                            {
                                Header: "Valor",
                                accessor: "valor",
                                width: 110,
                                sortMethod: (a, b) => {
                                    a = parseFloat(a);
                                    b = parseFloat(b);
                                    if (a < b) {
                                        return -1;
                                    } else if(a > b) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                },
                                Footer: row => (
                                <span>
                                    {// Get the total of the price
                                    "R$ " +
                                    Utils.float2moeda(
                                        row.data.reduce(
                                        (total, { valor }) => (total += parseFloat(valor)),
                                        0
                                        )
                                    )}
                                </span>
                                ),
                                Cell: row => (
                                <div
                                    style={{
                                    height: "22px",
                                    verticalAlign: "middle",
                                    position: "relative"
                                    }}
                                >
                                    {"R$ " + Utils.float2moeda(row.value)}
                                </div>
                                )
                            }, {
                              Header: "Ação",
                              accessor: "acao",
                              filterable: false,
                              width: 70,
                              Header: row => (this.state.conciliacao == true && this.state.selectedLancamento != '') ? (
                                <input
                                    type="checkbox"
                                    onChange={this.selectAllPagsRecbsHandleChange}
                                    checked={this.state.selectAll}
                                />
                              ) : (
                                ""
                              ),
                              Footer: row => {
                              return (
                              <span>
                                  {// Get the total of the price
                                  this.state.checked.filter((el) => el).length > 1 && this.state.selectedLancamento !== '' ?
                                  "R$ " +
                                  Utils.float2moeda(
                                      row.data.reduce(
                                      (total, { valor }, index) => 
                                        (total += this.state.checked[index] ? 
                                        parseFloat(valor) : 0)
                                      ,
                                      0
                                      )
                                  ) : ""}
                              </span>
                              )},
                              Cell: row =>
                              <div
                                style={{
                                  height: "22px",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  position: "relative"
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-around"
                                  alignItems="baseline"
                                >
                                  <FormatAlignJustifyIcon 
                                    style={{ cursor: "pointer" }}
                                    onClick={event => {
                                      this.setState({
                                        detailedPayment: row['original'].id_lancamento
                                      }, () => {
                                        this.getPaymentDetails();
                                      })
                                    }}
                                  />
                                  {
                                  (
                                    // Se não estou conciliando nada ou estou conciliando esse lancamento
                                    hasConciliationPermission && (this.state.conciliacao == false ||
                                    this.state.selectedPgtoRecb == row['original'].id_lancamento) 
                                  ) ? (
                                  <CachedIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={event => {
                                      if (this.state.conciliacao == true) {
                                        this.setState( {
                                            selectedPgtoRecb: '',
                                            conciliacao: false,
                                            selectAll: false,
                                            checked: []
                                          })
                                      } else {
                                        this.setState( {
                                          selectedPgtoRecb: row['original'].id_lancamento,
                                          conciliacao: true
                                        }) 
                                      }
                                    }}
                                  />
                                  ) : (
                                      ""
                                  )}
                                  {
                                  (
                                    // Se estou conciliando e parti pelo pagamento/receb
                                    (this.state.conciliacao == true && this.state.selectedLancamento != '') 
                                  )
                                  ? (
                                  <div style={{textAlign:'center'}}>
                                      <input
                                          type="checkbox"
                                          checked={this.state.checked[row.index]}
                                          onChange={() => this.singleCheckboxHandleChange(row.index)}
                                      />
                                  </div>
                                  ) : (
                                      ""
                                  )}
                                </Grid>
                              </div>
                            }
                            ]
                        }
                  />
                </Grid>
              </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-end"
            style={{margin:'auto'}}
            >
              <h2>
                  Conciliações Realizadas
              </h2>
              <Grid container direction="row" justify="space-around" style={{marginTop: "1em", margin: "auto"}}>
                <ReactTable
                  filterable
                  sortable={false}
                  ref={this.reactTable}
                  data={this.state.conciliacoesList}
                  defaultPageSize={10}
                  defaultFilterMethod={(filter, row) =>
                      (filter.id === 'data_banco' || filter.id === 'data')?(
                          Utils.dateToPT(String(row[filter.id])).startsWith(filter.value.toLowerCase())
                      ):(
                          String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                      )
                  }
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row && state.data) {
                      if (rowInfo.index > 0 && state.data.length > 0) {
                        let this_id_banco = rowInfo.row.id_lancamento_bancario;
                        let this_id_ambgest = rowInfo.row.id_lancamento;
                        let last_id_banco = state.data[rowInfo.index - 1] ? state.data[rowInfo.index - 1].id_lancamento_bancario : "";
                        let last_id_ambgest = state.data[rowInfo.index - 1] ? state.data[rowInfo.index - 1].id_lancamento : "";
                        
                        if (!(this_id_banco && last_id_banco)) {
                          if (this_id_ambgest == last_id_ambgest) {
                            state.offset = (state.offset ? state.offset : 0) + 1;
                            rowInfo.row.hideDeleteIcon = true;
                          }  
                        } else if(!(this_id_ambgest && last_id_ambgest)) {
                          if (this_id_banco == last_id_banco) {
                            state.offset = (state.offset ? state.offset : 0) + 1;
                            rowInfo.row.hideDeleteIcon = true;
                          }  
                        } else if (this_id_ambgest == last_id_ambgest || this_id_banco == last_id_banco) {
                          state.offset = (state.offset ? state.offset : 0) + 1;
                          rowInfo.row.hideDeleteIcon = true;
                        }
                      }
                      
                      return {
                        style: {
                          background: (rowInfo.index + (state.offset ? state.offset : 0)) % 2 == 0 ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0)'
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
                  columns={[
                    {
                      Header: "Nº - Banco",
                      accessor: "id_lancamento_bancario",
                      width: 100
                    },
                    {
                        Header: "Data - Banco",
                        id: 'data_banco',
                        accessor: 'data_banco',
                        width: 110,
                        sortMethod: (a, b) => {
                            var a1 = new Date(a).getTime();
                            var b1 = new Date(b).getTime();
                            if (a1 < b1) {
                                return 1;
                            } else if(a1 > b1) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        Cell: row => (
                            Utils.dateToPT(row.value)
                        )
                    },
                    {
                        Header: "Valor - Banco",
                        accessor: "valor_banco",
                        width: 110,
                        sortMethod: (a, b) => {
                            a = parseFloat(a);
                            b = parseFloat(b);
                            if (a < b) {
                                return -1;
                            } else if(a > b) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                        Footer: row => (
                        <span>
                            {// Get the total of the price
                            "R$ " +
                            Utils.float2moeda(                              
                              row.data.filter((data, index, self) => 
                                index === self.findIndex((datum) => datum.id_lancamento_bancario === data.id_lancamento_bancario)
                              ).reduce(
                                (total, { valor_banco }) => (total += parseFloat(valor_banco ? valor_banco : 0)),
                                0
                                )
                            )}
                        </span>
                        ),
                        Cell: row => (
                        <div
                            style={{
                            height: "22px",
                            verticalAlign: "middle",
                            position: "relative"
                            }}
                        >
                            {row.value ? "R$ " + Utils.float2moeda(row.value) : ""}
                        </div>
                        )
                    }, {
                      Header: "Nº - Ambgest",
                      accessor: "id_lancamento",
                      width: 100
                    },
                    {
                        Header: "Data - Ambgest",
                        id: 'data',
                        accessor: 'data',
                        width: 110,
                        sortMethod: (a, b) => {
                            var a1 = new Date(a).getTime();
                            var b1 = new Date(b).getTime();
                            if (a1 < b1) {
                                return 1;
                            } else if(a1 > b1) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        Cell: row => (
                            Utils.dateToPT(row.value)
                        )
                    },
                    {
                        Header: "Valor - Ambgest",
                        accessor: "valor",
                        width: 110,
                        sortMethod: (a, b) => {
                            a = parseFloat(a);
                            b = parseFloat(b);
                            if (a < b) {
                                return -1;
                            } else if(a > b) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                        Footer: row => (
                        <span>
                            {// Get the total of the price
                            "R$ " +
                            Utils.float2moeda(
                              row.data.filter((data, index, self) => 
                                index === self.findIndex((datum) => datum.id_lancamento === data.id_lancamento)
                              ).reduce(
                                (total, { valor }) => (total += parseFloat(valor ? valor : 0)),
                                0
                                )
                            )}
                        </span>
                        ),
                        Cell: row => (
                        <div
                            style={{
                            height: "22px",
                            verticalAlign: "middle",
                            position: "relative"
                            }}
                        >
                            {row.value ? "R$ " + Utils.float2moeda(row.value) : ""}
                        </div>
                        )
                    }, {
                      Header: "Justificativa",
                      accessor: "descricao",
                      width: 100
                    }, {
                        Header: "Ação",
                        accessor: "acao",
                        filterable: false,
                        width: 80,
                        Cell: row => {
                        return (
                        <div
                          style={{
                            height: "22px",
                            textAlign: "center",
                            verticalAlign: "middle",
                            position: "relative"
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="baseline"
                          >
                          {
                          hasConciliationPermission && !row.row.hideDeleteIcon ? (
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={event =>
                              this.setState( {
                                id_conciliacao_delete: row['original'].id_conciliacao,
                                dialogConfirmationDelete: true
                              })
                            }
                          />
                          ) : (
                              ""
                          )}
                          </Grid>
                        </div>
                        )
                        }
                      }
                      ]
                  }
                />
              </Grid>
          </Grid>
        </Grid>
        {this.state.dialogConfirmationDelete && (
          <Modal
              titulo="Confirmação"
              onCloseButton={() =>
                  this.setState({
                    dialogConfirmationDelete: false,
                    id_lancamento_delete: '',
                    id_conciliacao_delete: ''
                  })
              }
              isOpen={this.state.dialogConfirmationDelete}
          >
            <Grid
                container
                spacing={32}
            >
              <Grid item md={10}>
                Deseja confirmar a remoção { this.state.id_lancamento_delete === "" ? 'da conciliação' : 'do lançamento bancário'}?
              </Grid>
              <Grid container item md={10} direction="row" justify="center" alignItems="center">
                <Button onClick={() => this.state.id_lancamento_delete === "" ? this.removeConciliacao() : this.removeLancamentoBancario()} color="primary" variant="contained">
                  Deletar
                </Button>
              </Grid>
            </Grid>
          </Modal>
        )}
        {this.state.dialogConfirmationConciliation && (
          <Modal
              titulo="Atenção!"
              onCloseButton={() =>
                  this.setState({
                    dialogConfirmationConciliation: false
                  })
              }
              isOpen={this.state.dialogConfirmationConciliation}
              fullWidth={true}
              maxWidth="sm"
          >
            <Grid
                container
                spacing={32}
            >
              <Grid item md={10}>
                Os valores selecionados não são iguais.
                <br/>
                Lançamentos Bancários: R${Utils.float2moeda(this.state.bankTotal)}
                <br/>
                Lançamentos Ambgest: R${Utils.float2moeda(this.state.ambgestTotal)}
                <br/><br/>
                Por favor justifique a diferença:
                <TextField 
                  fullWidth
                  value={this.state.description}
                  label={'Justificativa'}
                  name="description"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid container item md={10} direction="row" justify="center" alignItems="center">
                <Button onClick={() => this.confirmaConciliacao()} color="primary" variant="contained">
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Modal>
        )}
        {this.state.dialogPaymentDetails && (
          <Modal
              titulo="Detalhes do Pagamento"
              onCloseButton={() =>
                  this.setState({
                    dialogPaymentDetails: false,
                    detailedPayment: "",
                    detailedPaymentData: ""
                  })
              }
              isOpen={this.state.dialogPaymentDetails}
              fullWidth={true}
              maxWidth="sm"
          >
            <Grid
                container
                spacing={32}
            >
              <Grid item sm={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                      <TextField label={'Tipo do Titulo'}
                          name="title_type"
                          value={this.switchNatureza(this.state.detailedPaymentData.natureza)}
                          editable={false}>
                      </TextField>
                      <TextField label={'Titulo'}
                          name="title"
                          value={this.state.detailedPaymentData.id_titulo + ' - ' + this.state.detailedPaymentData.descricao}
                          editable={false}>
                      </TextField>
                      <TextField label={'Emissor do Titulo'}
                          name="title_emitter"
                          value={this.switchContraparte(this.state.detailedPaymentData.tipo_contraparte)}
                          editable={false}>
                      </TextField>
                      <TextField label={'Nome Contraparte'}
                          name="name"
                          value={this.state.detailedPaymentData.nome_contraparte}
                          editable={false}>
                      </TextField>
                      <TextField label={'Projeto'}
                          name="project"
                          value={this.state.detailedPaymentData.nome_display}
                          editable={false}>
                      </TextField>
                      <TextField label={'Conta'}
                          name="account"
                          value={this.state.detailedPaymentData.id_conta}
                          editable={false}>
                      </TextField>
                      <CurrencyInput
                          allowNegative={true}
                          label={"Valor"}
                          name={"titleValue"}
                          value={parseFloat(this.state.detailedPaymentData.valor)}
                          editable={false}
                      />
                  </FormControl>
              </Grid>
              <Grid item sm={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                      <TextField label={'Data de de Pgto/Receb'}
                          name="titleDate"
                          value={this.state.detailedPaymentData.dt_pag_recb ? Utils.dateToPT(this.state.detailedPaymentData.dt_pag_recb) : ""}
                          editable={false}
                          InputProps={{
                              inputComponent: DateMaskCustom
                          }}>
                      </TextField>
                      <TextField label={'Data de Vencimento'}
                          name="expirationDate"
                          value={this.state.detailedPaymentData.dt_vencimento ? Utils.dateToPT(this.state.detailedPaymentData.dt_vencimento) : ""}
                          editable={false}
                          InputProps={{
                              inputComponent: DateMaskCustom
                          }}>
                      </TextField>
                      <TextField label={'Descrição'}
                          name="description"
                          value={this.state.detailedPaymentData.descricao}
                          editable={false}>
                      </TextField>
                      <TextField label={'Forma de Pagamento'}
                          name="payment_method"
                          value={this.state.detailedPaymentData.forma_pagamento}
                          editable={false}>
                      </TextField>
                      <TextField label={'Banco'}
                          name="bank"
                          value={this.state.detailedPaymentData.banco_pgto_receb ? this.state.detailedPaymentData.banco_pgto_receb : ''}
                          editable={false}>
                      </TextField>
                      <TextField label={'Nº do Documento'}
                          name="document_number"
                          value={this.state.detailedPaymentData.num_documento ? this.state.detailedPaymentData.num_documento : ''}
                          editable={false}>
                      </TextField>
                  </FormControl>
              </Grid>
            </Grid>
          </Modal>
        )}
      </div>
    );
  }
}

export default Conciliacao;
