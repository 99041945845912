import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  Icon,
} from '@material-ui/core';
import AppState from '../../../state';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { Utils } from '../../../services';
import { browserHistory } from 'react-router';
import AutoSelect from '../../../components/inputs/AutoSelect';
import CurrencyInput from '../../../components/lib/CurrencyInput';
import StarRating from 'react-svg-star-rating';

const styles = {
  halfGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto',
    flexWrap: 'auto',
  },
};

const DateMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

DateMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo_pago: false,
      id_titulo: '',
      edicao: false,
      accountList: [],
      account: '',
      accountErro: false,
      account_group: '',
      account_groupErro: false,
      accountGroupList: [],
      check_number: '',
      check_numberErro: false,
      contract: '',
      contractErro: false,
      contractList: [],
      cpf_or_cnpj: '',
      cpf_or_cnpjErro: false,
      current_or_not_current: '',
      // current_or_not_currentErro:false,
      emmit_at: Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ),
      emmit_atErro: false,
      employee: '',
      employeeList: [],
      employeeErro: false,
      expire_at: '',
      expire_atErro: false,
      name_or_company_name: '',
      name_or_company_nameErro: false,
      number_of_document: '',
      number_of_documentErro: false,
      payment_launch: 'false',
      // payment_launchErro: false,
      payment_mode: '',
      payment_modeErro: false,
      paymentModeList: [],
      description: '',
      descriptionError: false,
      project: '',
      projectErro: false,
      projectList: [],
      provider: '',
      providerList: [],
      providerErro: false,
      responsible: '',
      responsibleErro: false,
      title_issuer: '',
      title_issuerErro: false,
      type_of_title: 'despesa',
      type_of_titleErro: false,
      titleValue: '',
      titleValueErro: false,
      pj: '',
      pjErro: false,
      pjList: [],
      cliente: '',
      clienteErro: false,
      clienteList: [],
      bankList: [],
      pgtoRecebBank: '',
      pgtoRecebBankErro: false,
      paymentDate: Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ),
      paymentDateErro: false,
      avaliacao: '',
      avalicaoErro: false,
      title_status: '',
      title_approver: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    AppState.isLoading = this.props.isLoading;
    this.getProjects(this.props.isLoading);
    this.getGrupoContas(this.props.isLoading);
    this.getPaymentModes(this.props.isLoading);
    this.getBankList(this.props.isLoading);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.edicao != this.props.edicao) {
      this.setState(
        {
          edicao: this.props.edicao,
        },
        () => {
          if (this.props.edicao) {
            this.getTitulo(this.props.titulo);
          }
        }
      );
    }
  }

  closeDialog = () => {
    this.setState({
      titulo_pago: false,
      id_titulo: '',
      edicao: false,
      accountList: [],
      account: '',
      accountErro: false,
      account_group: '',
      account_groupErro: false,
      check_number: '',
      check_numberErro: false,
      contract: '',
      contractErro: false,
      contractList: [],
      cpf_or_cnpj: '',
      cpf_or_cnpjErro: false,
      current_or_not_current: '',
      // current_or_not_currentErro:false,
      emmit_at: Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ),
      emmit_atErro: false,
      employee: '',
      employeeList: [],
      employeeErro: false,
      expire_at: '',
      expire_atErro: false,
      name_or_company_name: '',
      name_or_company_nameErro: false,
      number_of_document: '',
      number_of_documentErro: false,
      payment_launch: 'false',
      // payment_launchErro: false,
      payment_mode: '',
      payment_modeErro: false,
      description: '',
      descriptionError: false,
      project: '',
      projectErro: false,
      provider: '',
      providerList: [],
      providerErro: false,
      responsible: '',
      responsibleErro: false,
      title_issuer: '',
      title_issuerErro: false,
      type_of_title: 'despesa',
      type_of_titleErro: false,
      titleValue: '',
      titleValueErro: false,
      pj: '',
      pjErro: false,
      pjList: [],
      cliente: '',
      clienteErro: false,
      clienteList: [],
      pgtoRecebBank: '',
      pgtoRecebBankErro: false,
      paymentDate: Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ),
      paymentDateErro: false,
      avaliacao: '',
      avalicaoErro: false,
      title_status: '',
      title_approver: '',
    });
    this.props.close();
  };

  BancoDoPagamentoHandleChange = (value) => {
    if (value === null) {
      this.setState({
        pgtoRecebBank: '',
      });
    } else {
      this.setState({
        pgtoRecebBank: value.label,
      });
    }
  };

  funcionarioHandleChange = (value) => {
    if (value === null) {
      this.setState({
        employee: '',
      });
    } else {
      this.setState(
        {
          employee: value.value,
        },
        () => this.getProjectByUser(value.value)
      );
    }
  };

  fornecedorHandleChange = (value) => {
    if (value === null) {
      this.setState({
        provider: '',
      });
    } else {
      this.setState(
        {
          provider: value.value,
        },
        () => this.getContratos()
      );
    }
  };

  pjHandleChange = (value) => {
    if (value === null) {
      this.setState({
        pj: '',
      });
    } else {
      this.setState(
        {
          pj: value.value,
        },
        () => this.getProjectByUser(value.value)
      );
    }
  };

  clienteHandleChange = (value) => {
    if (value === null) {
      this.setState({
        cliente: '',
      });
    } else {
      this.setState(
        {
          cliente: value.value,
        },
        () => this.getProjectByCliente(value.value)
      );
    }
  };

  accountGroupHandleChange = (event) => {
    this.setState(
      {
        account_group: event.target.value,
      },
      () => {
        this.getContas();
      }
    );

    if (this.state.type_of_title === 'controle') {
      if (event.target.value.substring(0, 3) === '3.8') {
        this.setState({
          payment_launch: 'false',
        });
      } else {
        this.setState({
          payment_launch: 'true',
        });
      }
    }
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  getProjects = (isLoading = false) => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetProjects.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            projectList: response.request,
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getProjectByUser = (user) => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetUserProjects.php?json={"usuario":"${user}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          projectList: response.request.filter(function (item) {
            return item.dt_encerramento == null;
          }),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - invalid/incomplete client CNPJ or CPF');
          }
          if (ret == '40002') {
            console.log('40002 - Dados icorretos/invalidos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getProjectByCliente = (cliente) => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetProjectsByClient.php?json={"cnpj":"${cliente}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          projectList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - invalid/incomplete client CNPJ or CPF');
          }
          if (ret == '40002') {
            console.log('40002 - Dados icorretos/invalidos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getContratos = (isLoading = false) =>
    new Promise((resolve, reject) => {
      AppState.isLoading = true;
      return fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/fornecedores/GetContratosLista.php?json={"cnpj_fornecedor":"${this.state.provider}"}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              let error = new Error(response.status);
              error.response = json;
              throw error;
            });
          } else {
            const res = response.json();
            return res;
            AppState.isLoading = false;
          }
        })
        .then((response) => {
          AppState.isLoading = isLoading;
          this.setState(
            {
              contractList: response.request,
            },
            () => resolve()
          );
        })
        .catch(function (error) {
          AppState.isLoading = false;
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            reject(error);
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40001' || ret == '40002') {
              console.log('40001 Erro desconhecido');
            } else alert('Dados inválidos');
          } else if (Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
          reject(error);
        });
    });

  getContas = (isLoading = false) =>
    new Promise((resolve, reject) => {
      AppState.isLoading = true;
      return fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetContasByGroup.php?json={"grupo_conta":"${this.state.account_group}"}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              let error = new Error(response.status);
              error.response = json;
              throw error;
            });
          } else {
            const res = response.json();
            return res;
            AppState.isLoading = false;
          }
        })
        .then((response) => {
          AppState.isLoading = isLoading;
          this.setState(
            {
              accountList: response.request,
            },
            () => resolve()
          );
        })
        .catch(function (error) {
          AppState.isLoading = false;
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            reject(error);
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40001') {
              console.log('40001 Erro desconhecido');
            } else alert('Dados inválidos');
          } else if (Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
          reject(error);
        });
    });

  getGrupoContas = (isLoading = false) =>
    new Promise((resolve, reject) => {
      const json = JSON.stringify({
        tipo: this.state.type_of_title,
      });

      fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetGrupoContas.php?json=${json}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              let error = new Error(response.status);
              error.response = json;
              throw error;
            });
          } else {
            const res = response.json();
            return res;
          }
        })
        .then((response) => {
          this.setState(
            {
              accountGroupList: response.request,
              accountList: [],
            },
            () => {
              if (isLoading == false) {
                AppState.isLoading = isLoading;
              }
              resolve();
            }
          );
        })
        .catch(function (error) {
          if (isLoading == false) {
            AppState.isLoading = isLoading;
          }
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            reject(error);
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40001') {
              console.log('40001 erro desconhecido');
            } else alert('Dados inválidos');
          } else if (Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
          reject(error);
        });
    });

  getPJ = (isLoading = false) => {
    AppState.isLoading = true;
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/usuarios/GetPJList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        const pj = response.request;

        this.setState(
          {
            pjList: pj.map((suggestion) => ({
              value: suggestion.cpf_pj,
              label: suggestion.nome_pj,
            })),
          },
          () => (AppState.isLoading = isLoading)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFuncionarios = (isLoading = false) => {
    AppState.isLoading = true;
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/funcionarios/GetFuncionarios.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        const employee = response.request;

        this.setState(
          {
            employeeList: employee.map((suggestion) => ({
              value: suggestion.cpf_funcionario,
              label: suggestion.nome_funcionario,
            })),
          },
          () => (AppState.isLoading = isLoading)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFornecedores = (isLoading = false) => {
    AppState.isLoading = true;
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/fornecedores/GetFornecedores.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        const provider = response.request;

        this.setState(
          {
            providerList: provider.map((suggestion) => ({
              value: suggestion.cpf_cnpj_fornecedor,
              label:
                suggestion.nome_fantasia == '' || !suggestion.nome_fantasia
                  ? suggestion.razao_social
                  : suggestion.nome_fantasia,
            })),
          },
          () => (AppState.isLoading = isLoading)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getClientes = (isLoading = false) => {
    AppState.isLoading = true;
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/cliente/GetClientes.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        const cliente = response.request;

        this.setState(
          {
            clienteList: cliente.map((suggestion) => ({
              value: suggestion.cpf_cnpj_cliente,
              label:
                suggestion.nome_fantasia == '' || !suggestion.nome_fantasia
                  ? suggestion.razao_social
                  : suggestion.nome_fantasia,
              is_active: suggestion.cliente_ativo,
            })),
          },
          () => (AppState.isLoading = isLoading)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getPaymentModes = (isLoading = false) => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetFormaPagamentoList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            paymentModeList: response.request,
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getBankList = (isLoading = false) => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetBancos.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const banks = response.request;

        this.setState(
          {
            bankList: banks.map((suggestion) => ({
              value: suggestion.banco,
              label: suggestion.banco,
            })),
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro Desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getTitulo = (titulo) => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetTitulo.php?json={"id_titulo":"${titulo}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            type_of_title: response.request[0].natureza,
            account_group: response.request[0].grupo,
            provider:
              response.request[0].tipo_contraparte === 'fornecedor'
                ? response.request[0].cpf_cnpj_contraparte
                : '',
          },
          () => {
            // Promise para atualizar lista de emissores dado o titulo recebido
            this.updateEmissorList(
              response.request[0].tipo_contraparte,
              true
            ).then(() => {
              // Atualiza lista de grupos de contas dada a natureza
              this.getGrupoContas(true).then(() => {
                // Atualiza contas dada a lista de contas
                this.getContas(true).then(() => {
                  // Atualiza os contratos dado o cpf
                  this.getContratos(true).then(() => {
                    // Resolvendo estado com todos as listas pre carregadas
                    AppState.isLoading = false;
                    this.setState({
                      id_titulo: response.request[0].id_titulo
                        ? response.request[0].id_titulo
                        : '',
                      id_pgto_receb: response.request[0].id_pgto_receb
                        ? response.request[0].id_pgto_receb
                        : '',
                      project: response.request[0].id_projeto
                        ? response.request[0].id_projeto
                        : '',
                      account: response.request[0].id_conta
                        ? response.request[0].id_conta
                        : '',
                      titleValue: parseFloat(response.request[0].valor),
                      emmit_at: Utils.dateToPT(
                        response.request[0].dt_lancamento
                      ),
                      expire_at: Utils.dateToPT(
                        response.request[0].dt_vencimento
                      ),
                      paymentDate: response.request[0].dt_pag_recb
                        ? Utils.dateToPT(response.request[0].dt_pag_recb)
                        : Utils.dateToPT(
                            new Date(
                              new Date().getTime() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .slice(0, 10)
                          ),
                      description: response.request[0].descricao
                        ? response.request[0].descricao
                        : '',
                      payment_mode: response.request[0].forma_pagamento
                        ? response.request[0].forma_pagamento
                        : '',
                      check_number: response.request[0].n_cheque
                        ? response.request[0].n_cheque
                        : '',
                      current_or_not_current:
                        response.request[0].eh_corrente == 't'
                          ? 'true'
                          : 'false',
                      payment_launch:
                        response.request[0].status == 'Pago' ? 'true' : 'false',
                      titulo_pago:
                        response.request[0].status == 'Pago' ? true : false,
                      number_of_document: response.request[0].num_documento
                        ? response.request[0].num_documento
                        : '',
                      pgtoRecebBank: response.request[0].banco_prev_pgto_receb
                        ? response.request[0].banco_prev_pgto_receb
                        : '',
                      title_issuer: response.request[0].tipo_contraparte
                        ? response.request[0].tipo_contraparte
                        : '',
                      employee:
                        response.request[0].tipo_contraparte === 'funcionario'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      contract: response.request[0].id_contrato
                        ? response.request[0].id_contrato
                        : '',
                      cpf_or_cnpj:
                        response.request[0].tipo_contraparte === 'outros'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      name_or_company_name: response.request[0]
                        .nome_contraparte_outro
                        ? response.request[0].nome_contraparte_outro
                        : '',
                      cliente:
                        response.request[0].tipo_contraparte === 'cliente'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      pj:
                        response.request[0].tipo_contraparte === 'pj'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      cpf_usuario: response.request[0].criado_por
                        ? response.request[0].criado_por
                        : '',
                      avaliacao: response.request[0].avaliacao_fornecedor
                        ? response.request[0].avaliacao_fornecedor
                        : '',
                      title_status: response.request[0].status
                        ? response.request[0].status
                        : '',
                      title_approver: response.request[0].aprovador
                        ? response.request[0].aprovador
                        : '',
                    });
                  });
                });
              });
            });
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        } else {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  validaForm = () => {
    let formValido = true;

    if (this.state.type_of_title == '') {
      formValido = false;
      this.setState({
        type_of_titleErro: true,
      });
    }

    if (
      this.state.payment_launch !== 'true' &&
      this.state.payment_launch !== 'false'
    ) {
      formValido = false;
      // this.setState({
      //     payment_launchErro: true
      // })
      alert('Há um erro na escolha de pagamento');
    }

    if (this.state.title_issuer === '') {
      formValido = false;
      this.setState({
        title_issuerErro: true,
      });
    }

    if (this.state.title_issuer === 'funcionario') {
      if (this.state.employee === '') {
        formValido = false;
        this.setState({
          employeeErro: true,
        });
      }
    }

    if (this.state.title_issuer === 'pj') {
      if (this.state.pj === '') {
        formValido = false;
        this.setState({
          pjErro: true,
        });
      }
    }

    if (this.state.title_issuer === 'fornecedor') {
      if (this.state.provider === '') {
        formValido = false;
        this.setState({
          providerErro: true,
        });
      }

      if (this.state.contract === '') {
        formValido = false;
        this.setState({
          contractErro: true,
        });
      }

      if (
        this.state.type_of_title === 'despesa' &&
        !this.state.account_group.includes('2.3')
      ) {
        if (
          this.state.avaliacao === '' &&
          this.state.edicao === false &&
          !this.state.account_group.includes('2.3')
        ) {
          formValido = false;
          this.setState({
            avalicaoErro: true,
          });
          alert('Por favor avalie o serviço prestado');
        }
      }
    }

    if (this.state.title_issuer === 'cliente') {
      if (this.state.cliente === '') {
        formValido = false;
        this.setState({
          clienteErro: true,
        });
      }
    }

    if (this.state.title_issuer === 'outros') {
      if (this.state.name_or_company_name === '') {
        formValido = false;
        this.setState({
          name_or_company_nameErro: true,
        });
      }

      if (this.state.cpf_or_cnpj === '') {
        formValido = false;
        this.setState({
          cpf_or_cnpjErro: true,
        });
      }
    }

    if (this.state.project === '') {
      formValido = false;
      this.setState({
        projectErro: true,
      });
    }

    if (this.state.account_group === '') {
      formValido = false;
      this.setState({
        account_groupErro: true,
      });
    }

    if (this.state.account === '') {
      formValido = false;
      this.setState({
        accountErro: true,
      });
    }

    if (this.state.titleValue === '') {
      formValido = false;
      this.setState({
        titleValueErro: true,
      });
    }

    if (this.state.description === '' || this.state.description.length > 256) {
      formValido = false;
      this.setState({
        descriptionErro: true,
      });
    }

    if (this.state.expire_at == '') {
      formValido = false;
      this.setState({
        expire_atErro: true,
      });
    } else {
      const res = this.state.expire_at.split('/');
      const dataMes = res[1];
      const dataDia = res[0];

      if (dataMes > 12 || dataDia > 31) {
        formValido = false;
        this.setState({
          expire_atErro: true,
        });
      }
    }
    const dataLancamento = Utils.dateToEN(this.state.emmit_at);
    const lancamento = new Date(dataLancamento);

    //Valida data de lançamento
    if (this.state.emmit_at == '') {
      formValido = false;
      this.setState({
        emmit_atErro: true,
      });
    } else {
      const endDate = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      );

      if (lancamento.getTime() > endDate.getTime()) {
        formValido = false;
        this.setState({
          emmit_atErro: true,
        });
      }
    }

    // Tratamento para erro na data de pagamento
    const date = new Date();
    const dataPagamento = Utils.dateToEN(this.state.paymentDate).replace(
      /-/g,
      '/'
    );
    const pagamento = new Date(dataPagamento);

    if (
      this.state.type_of_title === 'controle' &&
      pagamento.getTime() > date.getTime()
    ) {
      formValido = false;
      this.setState({
        paymentDateErro: true,
      });
    }

    // Para titulos de controle - onde o usuário pode editar a data de pagamento - checar se data de pagamento é menor que lancamento.
    if (
      this.state.type_of_title === 'controle' &&
      pagamento.getTime() < lancamento.getTime()
    ) {
      formValido = false;
      alert(
        'Não é possível pagar um titulo em uma data anterior ao lançamento'
      );
      this.setState({
        paymentDateErro: true,
      });
    }

    if (this.state.titleValue === '' || this.state.titleValue === 'R$ 0') {
      formValido = false;
      this.setState({
        titleValueErro: true,
      });
    }

    // if(this.state.payment_launch === 'true'){
    //     if(this.state.payment_mode === ''){
    //         formValido = false
    //         this.setState({
    //             payment_modeErro: true
    //         })
    //     }
    // }

    // if((this.state.payment_launch === 'true' && this.state.payment_mode === 'Transferência - DOC') ||
    //     (this.state.payment_launch === 'true' && this.state.payment_mode === 'Transferência - TED')){
    //     if(this.state.pgtoRecebBank === ''){
    //         formValido = false
    //         alert('Por favor preencha o Banco do Pgto./Receb.')
    //         this.setState({
    //             pgtoRecebBankErro: true
    //         })
    //     }
    // }

    if (
      this.state.type_of_title === 'despesa' ||
      this.state.type_of_title === 'controle'
    ) {
      if (
        this.state.current_or_not_current !== 'true' &&
        this.state.current_or_not_current !== 'false'
      ) {
        formValido = false;
        // this.setState({
        //     current_or_not_currentErro: true
        // })
        alert('Por favor selecione o Tipo de Conta');
      }
    }

    if (formValido === true) {
      if (this.state.edicao === true) {
        this.editTitulo();
      } else {
        this.addTitulo();
      }
    } else {
      alert(
        'Foram encontrados erros no formulário. Por favor corrija e tente novamente.'
      );
      // console.log(this.state, formValido)
    }
  };

  addTitulo = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      natureza: this.state.type_of_title,
      projeto: this.state.project,
      conta: this.state.account,
      valor: this.moeda2float(this.state.titleValue),
      dt_lancamento: Utils.dateToEN(this.state.emmit_at),
      dt_vencimento: Utils.dateToEN(this.state.expire_at),
      dt_pagamento: Utils.dateToEN(this.state.paymentDate),
      descricao: this.state.description,
      forma_pgto: this.state.payment_mode,
      numero_cheque: this.state.check_number,
      eh_corrente: this.state.current_or_not_current == 'true' ? true : false,
      numero_doc: this.state.number_of_document,
      banco_pgto: this.state.pgtoRecebBank,
      n_cheque: this.state.check_number,
      lancar_pgto: this.state.payment_launch,
      tipo_contraparte: this.state.title_issuer,
      cpf_funcionario: this.state.employee,
      cnpj_fornecedor: this.state.provider,
      n_contrato: this.state.contract,
      cpf_cnpj_outro: this.state.cpf_or_cnpj,
      nome_outro: this.state.name_or_company_name,
      cnpj_cliente: this.state.cliente,
      cpf_pj: this.state.pj,
      criado_por: AppState.userInfo.cpf_usuario,
      avaliacao: this.state.avaliacao,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/AddTitulo.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002' || ret == '40007') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40003') {
            console.log('Não é possivel fazer lançamento futuro');
            alert('Não é possivel fazer lançamento futuro');
          } else if (ret == '40004') {
            console.log('Não é possivel fazer lançamento em mês retroativo');
            alert('Não é possivel fazer lançamento em mês retroativo');
          } else if (ret == '40005') {
            console.log(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
            alert(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
          } else if (ret == '40006') {
            console.log(
              'Não é possível pagar um titulo antes de sua data de lançamento'
            );
            alert(
              'Não é possível pagar um titulo antes de sua data de lançamento'
            );
          } else if (ret == '40008') {
            console.log(
              'Não é possível lançar titulo com vencimento maior que 60 dias.'
            );
            alert(
              'Não é possível lançar titulo com vencimento maior que 60 dias.'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  editTitulo = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      id_titulo: this.state.id_titulo,
      id_pgto_receb: this.state.id_pgto_receb,
      natureza: this.state.type_of_title,
      projeto: this.state.project,
      conta: this.state.account,
      valor: this.moeda2float(this.state.titleValue),
      dt_lancamento: Utils.dateToEN(this.state.emmit_at),
      dt_vencimento: Utils.dateToEN(this.state.expire_at),
      dt_pagamento: Utils.dateToEN(this.state.paymentDate),
      descricao: this.state.description,
      forma_pgto: this.state.payment_mode,
      numero_cheque: this.state.check_number,
      eh_corrente: this.state.current_or_not_current == 'true' ? true : false,
      numero_doc: this.state.number_of_document,
      banco_pgto: this.state.pgtoRecebBank,
      n_cheque: this.state.check_number,
      lancar_pgto: this.state.payment_launch,
      tipo_contraparte: this.state.title_issuer,
      cpf_funcionario: this.state.employee,
      cnpj_fornecedor: this.state.provider,
      n_contrato: this.state.contract,
      cpf_cnpj_outro: this.state.cpf_or_cnpj,
      nome_outro: this.state.name_or_company_name,
      cnpj_cliente: this.state.cliente,
      cpf_pj: this.state.pj,
      criado_por: AppState.userInfo.cpf_usuario,
      avaliacao: this.state.avaliacao,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/EditTitulo.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002' || ret == '40007') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40003') {
            console.log(
              'Não é possivel lançar ou pagar um titulo em datas futuras'
            );
            alert('Não é possivel lançar ou pagar um titulo em datas futuras');
          } else if (ret == '40004') {
            console.log(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
            alert(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
          } else if (ret == '40005') {
            console.log(
              'Não é possivel alterar data de lançamento ou pagamento no projeto 0'
            );
            alert(
              'Não é possivel alterar data de lançamento ou pagamento no projeto 0'
            );
          } else if (ret == '40006') {
            console.log(
              'Não é possivel lançar ou pagar um titulo em um mês retroativo'
            );
            alert(
              'Não é possivel lançar ou pagar um titulo em um mês retroativo'
            );
          } else if (ret == '40008') {
            console.log('Este titulo já foi Pago.');
            alert('Este titulo já foi Pago.');
          } else {
            console.log(ret);
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  tipoHandleChange = (event) => {
    this.setState(
      {
        type_of_title: event.target.value,
        paymentDate: Utils.dateToPT(
          new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 10)
        ),
      },
      () => this.getGrupoContas()
    );

    if (event.target.value === 'receita') {
      this.setState({
        payment_launch: 'true',
        current_or_not_current: 'true',
      });
    } else if (event.target.value === 'controle') {
      this.setState({
        payment_launch: 'true',
        current_or_not_current: '',
      });
    } else {
      this.setState({
        payment_launch: 'false',
        current_or_not_current: '',
      });
    }
  };

  moeda2float = (moeda) => {
    if (moeda === '') {
      return '';
    } else {
      if (typeof moeda === 'string') {
        moeda = moeda.replace('R$ ', '');
        moeda = moeda.replace(/\./g, '');
        moeda = moeda.replace(/\,/g, '.');
        moeda = moeda.replace(/\ /g, '');
      }
      return parseFloat(moeda);
    }
  };

  updateEmissorList = async (value, isLoading = false) =>
    new Promise((resolve, reject) => {
      switch (value) {
        case 'funcionario':
          this.getFuncionarios(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case 'pj':
          this.getPJ(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case 'fornecedor':
          this.getFornecedores(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case 'cliente':
          this.getClientes(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        default:
          resolve(null);
          break;
      }
    });

  emissorHandleChange = (event) => {
    this.setState({
      pj: '',
      employee: '',
      project: '',
      account_group: '',
      provider: '',
      account: '',
      contract: '',
      cliente: '',
      name_or_company_name: '',
      cpf_or_cnpj: '',
      title_issuer: event.target.value,
      avaliacao: '',
    });
    this.updateEmissorList(event.target.value);
    this.getProjects();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        disableBackdropClick={true}
        fullScreen
      >
        <DialogContent>
          <Grid
            container={true}
            alignItems={'flex-start'}
            justify={'space-around'}
            direction={'row'}
            spacing={40}
          >
            <Grid
              item
              sm={10}
              container
              direction="row"
              justify="space-between"
              style={{
                maxWidth: '100%',
                flexGrow: '1',
                flexBasis: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Typography variant="h4" style={{ marginBottom: '40px' }}>
                Lançamento de Título
              </Typography>
              <Icon
                style={{ float: 'right' }}
                title={'fechar'}
                onClick={this.closeDialog}
              >
                close
              </Icon>
            </Grid>
          </Grid>

          <Grid container spacing={32}>
            <Grid item sm={6}>
              {this.state.edicao && (
                <Grid container spacing={8}>
                  <Grid item sm={6} style={styles.halfGrid}>
                    <FormControl component="fieldset" fullWidth>
                      <TextField
                        label={'Status do Título'}
                        name="title_status"
                        value={this.state.title_status}
                        disabled={true}
                      ></TextField>
                    </FormControl>
                    <FormControl component="fieldset" fullWidth>
                      <TextField
                        label={'Aprovador'}
                        name="title_status"
                        value={this.state.title_approver}
                        disabled={true}
                      ></TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={8}>
                <Grid item sm={6} style={styles.halfGrid}>
                  <FormControl component="fieldset" fullWidth>
                    <InputLabel>Tipo do Título</InputLabel>
                    <Select
                      name="type_of_title"
                      // row={true}
                      value={this.state.type_of_title}
                      disabled={this.state.titulo_pago}
                      onChange={this.tipoHandleChange}
                      error={this.state.type_of_titleErro}
                    >
                      <MenuItem value={'despesa'}>à Pagar</MenuItem>
                      <MenuItem value={'receita'}>à Receber</MenuItem>
                      <MenuItem value={'controle'}>Controle</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel>
                      {this.state.type_of_title == 'receita'
                        ? 'Efetivar Recebimento '
                        : 'Efetivar Pagamento'}
                    </FormLabel>
                    <RadioGroup
                      aria-label="lançar pagamento"
                      name="payment_launch"
                      row={true}
                      value={this.state.payment_launch}
                      onChange={(e, value) =>
                        this.setState({ payment_launch: value })
                      }
                      // error={this.state.payment_launchErro}
                    >
                      <FormControlLabel
                        value={'true'}
                        key={0}
                        control={<Radio color="primary" />}
                        disabled={true}
                        label="Sim"
                      />
                      <FormControlLabel
                        value={'false'}
                        key={1}
                        control={<Radio color="primary" />}
                        disabled={true}
                        label="Não"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <InputLabel htmlFor="title_issuer">
                  Emissor do Título
                </InputLabel>
                <Select
                  error={this.state.title_issuerErro}
                  value={this.state.title_issuer}
                  inputProps={{
                    name: 'title_issuer',
                    id: 'title_issuer',
                  }}
                  onChange={this.emissorHandleChange}
                >
                  <MenuItem value={'funcionario'}>Funcionário</MenuItem>
                  <MenuItem value={'pj'}>Pessoa Jurídica (PJ)</MenuItem>
                  <MenuItem value={'fornecedor'}>Fornecedor</MenuItem>
                  <MenuItem value={'cliente'}>Cliente</MenuItem>
                  <MenuItem value={'outros'}>Outros</MenuItem>
                </Select>
              </FormControl>

              {this.state.title_issuer === 'funcionario' && (
                <FormControl fullWidth={true}>
                  <AutoSelect
                    value={this.state.employee}
                    initialValue={this.state.employee}
                    suggestions={this.state.employeeList}
                    handleChange2={this.funcionarioHandleChange}
                    placeholder="Selecione o Funcionário"
                    error={this.state.employeeErro}
                  />
                </FormControl>
              )}

              {/*<FormControl fullWidth={true}>*/}
              {/*<InputLabel htmlFor="employee">Selecione o Funcionário</InputLabel>*/}
              {/*<Select*/}
              {/*value={this.state.employee}*/}
              {/*inputProps={{*/}
              {/*name: 'employee',*/}
              {/*id: 'employee',*/}
              {/*}}*/}
              {/*error={this.state.employeeErro}*/}
              {/*onChange={this.funcionarioHandleChange}*/}
              {/*>*/}
              {/*{this.state.employeeList.map((item)=>(*/}
              {/*<MenuItem key={item.cpf_funcionario} value={item.cpf_funcionario}>{item.nome_funcionario}</MenuItem>*/}
              {/*))}*/}

              {/*</Select>*/}
              {/*</FormControl>*/}

              {this.state.title_issuer === 'pj' && (
                <FormControl fullWidth={true}>
                  <AutoSelect
                    value={this.state.pj}
                    suggestions={this.state.pjList}
                    handleChange2={this.pjHandleChange}
                    initialValue={this.state.pj}
                    placeholder="Selecione o PJ"
                    error={this.state.pjErro}
                  />
                </FormControl>
              )}

              {/*<FormControl fullWidth={true}>*/}
              {/*<InputLabel>Selecione o PJ</InputLabel>*/}
              {/*<Select*/}
              {/*value={this.state.pj}*/}
              {/*onChange={this.pjHandleChange}*/}
              {/*error={this.state.pjErro}*/}
              {/*>*/}
              {/*{this.state.pjList.map((item)=>(*/}
              {/*<MenuItem key={item.cpf_pj} value={item.cpf_pj}>{item.nome_pj}</MenuItem>*/}
              {/*))}*/}

              {/*</Select>*/}
              {/*</FormControl>*/}

              {this.state.title_issuer === 'fornecedor' && (
                <FormControl fullWidth={true}>
                  <AutoSelect
                    value={this.state.provider}
                    suggestions={this.state.providerList}
                    initialValue={this.state.provider}
                    handleChange2={this.fornecedorHandleChange}
                    placeholder="Selecione o Fornecedor"
                    error={this.state.providerErro}
                  />
                </FormControl>
              )}

              {/*<FormControl fullWidth={true}>*/}
              {/*<InputLabel htmlFor="provider">Selecione o Fornecedor</InputLabel>*/}
              {/*<Select*/}
              {/*value={this.state.provider}*/}
              {/*inputProps={{*/}
              {/*name: 'provider',*/}
              {/*id: 'provider',*/}
              {/*}}*/}
              {/*onChange={this.fornecedorHandleChange}*/}
              {/*error={this.state.providerErro}*/}
              {/*>*/}
              {/*{this.state.providerList.map((item)=>(*/}
              {/*<MenuItem key={item.cpf_cnpj_fornecedor} value={item.cpf_cnpj_fornecedor}>{item.nome_fantasia ? item.nome_fantasia : item.razao_social}</MenuItem>*/}
              {/*))}*/}
              {/*</Select>*/}
              {/*</FormControl>*/}

              {this.state.title_issuer === 'cliente' && (
                <FormControl fullWidth={true}>
                  <AutoSelect
                    value={this.state.cliente}
                    suggestions={this.state.clienteList.filter((cliente) => {
                      return (
                        cliente.is_active == 't' ||
                        cliente.value == this.state.cliente
                      );
                    })}
                    handleChange2={this.clienteHandleChange}
                    initialValue={this.state.cliente}
                    placeholder="Selecione o Cliente"
                    error={this.state.clienteErro}
                  />
                </FormControl>
              )}

              {/*<FormControl fullWidth={true}>*/}
              {/*<InputLabel htmlFor="provider">Selecione o Cliente</InputLabel>*/}
              {/*<Select*/}
              {/*value={this.state.cliente}*/}
              {/*inputProps={{*/}
              {/*name: 'provider',*/}
              {/*id: 'provider',*/}
              {/*}}*/}
              {/*onChange={this.clienteHandleChange}*/}
              {/*error={this.state.clienteErro}*/}
              {/*>*/}
              {/*{this.state.clienteList.map((item)=>(*/}
              {/*<MenuItem key={item.cpf_cnpj_cliente} value={item.cpf_cnpj_cliente}>{item.nome_fantasia ? item.nome_fantasia : item.razao_social}</MenuItem>*/}
              {/*))*/}
              {/*}*/}
              {/*</Select>*/}
              {/*</FormControl>*/}

              {this.state.title_issuer === 'fornecedor' && (
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="contract">
                    Selecione o Contrato
                  </InputLabel>
                  <Select
                    value={this.state.contract}
                    inputProps={{
                      name: 'contract',
                      id: 'contract',
                    }}
                    onChange={this.handleChange}
                    error={this.state.contractErro}
                  >
                    {this.state.contractList.map((item) => (
                      <MenuItem key={item.id_contrato} value={item.id_contrato}>
                        {item.display_nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {this.state.title_issuer === 'outros' && (
                <TextField
                  value={this.state.name_or_company_name}
                  label={'Nome'}
                  name={'name_or_company_name'}
                  fullWidth
                  onChange={this.handleChange}
                  error={this.state.name_or_company_nameErro}
                />
              )}

              {this.state.title_issuer === 'outros' && (
                <TextField
                  value={this.state.cpf_or_cnpj}
                  label={'CPF'}
                  name={'cpf_or_cnpj'}
                  fullWidth
                  onChange={this.handleChange}
                  InputProps={{
                    inputComponent: CPFMaskCustom,
                  }}
                  error={this.state.cpf_or_cnpjErro}
                />
              )}

              <FormControl fullWidth={true}>
                <InputLabel htmlFor="project">Selecione o Projeto</InputLabel>
                <Select
                  value={this.state.project}
                  inputProps={{
                    name: 'project',
                    id: 'project',
                  }}
                  onChange={this.handleChange}
                  error={this.state.projectErro}
                >
                  {this.state.projectList.map((item) => (
                    <MenuItem key={item.id_projeto} value={item.id_projeto}>
                      {item.id_projeto} - {item.desc_curta}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth={true}>
                <InputLabel htmlFor="account_group">Grupo de Contas</InputLabel>
                <Select
                  value={this.state.account_group}
                  inputProps={{
                    name: 'account_group',
                    id: 'account_group',
                  }}
                  onChange={this.accountGroupHandleChange}
                  error={this.state.account_groupErro}
                >
                  {this.state.accountGroupList.map((item) => (
                    <MenuItem key={item.id_conta} value={item.id_conta}>
                      {item.nome_display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth={true}>
                <InputLabel htmlFor="account">Conta</InputLabel>
                <Select
                  value={this.state.account}
                  inputProps={{
                    name: 'account',
                    id: 'account',
                  }}
                  onChange={this.handleChange}
                  error={this.state.accountErro}
                >
                  {this.state.accountList.map((item) => (
                    <MenuItem key={item.id_conta} value={item.id_conta}>
                      {item.nome_display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <CurrencyInput
                  allowNegative={true}
                  label={'Valor'}
                  name={'titleValue'}
                  inputProps={{
                    readOnly:
                      this.state.account.substring(0, 3) == '2.3' &&
                      this.state.edicao,
                  }}
                  value={this.state.titleValue}
                  error={this.state.titleValueErro}
                  onChange={this.handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item sm={6}>
              <FormControl fullWidth={true}>
                <TextField
                  label={'Data de Lançamento'}
                  value={this.state.emmit_at}
                  type={'text'}
                  name={'emmit_at'}
                  onChange={this.handleChange}
                  InputProps={{
                    inputComponent: DateMaskCustom,
                  }}
                  disabled={
                    this.state.edicao &&
                    this.state.project === '0' &&
                    this.state.type_of_title !== 'controle'
                  }
                  error={this.state.emmit_atErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Data de Vencimento'}
                  value={this.state.expire_at}
                  type={'text'}
                  name={'expire_at'}
                  onChange={this.handleChange}
                  InputProps={{
                    inputComponent: DateMaskCustom,
                  }}
                  error={this.state.expire_atErro}
                />
              </FormControl>

              {this.state.payment_launch === 'true' &&
                this.state.type_of_title === 'controle' && (
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Data de Pagamento'}
                      value={this.state.paymentDate}
                      type={'text'}
                      name={'paymentDate'}
                      onChange={this.handleChange}
                      InputProps={{
                        inputComponent: DateMaskCustom,
                      }}
                      error={this.state.paymentDateErro}
                    />
                  </FormControl>
                )}

              <TextField
                fullWidth
                value={this.state.description}
                label={'Descrição'}
                name={'description'}
                onChange={this.handleChange}
                error={this.state.descriptionErro}
              />

              <FormControl fullWidth={true}>
                <TextField
                  value={this.state.number_of_document}
                  label={'Nº do documento'}
                  name={'number_of_document'}
                  onChange={this.handleChange}
                  error={this.state.number_of_documentErro}
                />
              </FormControl>

              <FormControl component="fieldset">
                <FormLabel style={{ marginTop: '10px' }}>
                  Tipo de Conta
                </FormLabel>
                <RadioGroup
                  aria-label="corrent/não corrente"
                  name="current_or_not_current"
                  row={true}
                  value={this.state.current_or_not_current || 'not_current'}
                  onChange={(e, value) =>
                    this.setState({ current_or_not_current: value })
                  }
                  // error={this.state.current_or_not_currentErro}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Corrente"
                    disabled={
                      (this.state.title &&
                        this.state.current_or_not_current &&
                        !this.state.edicao) ||
                      this.state.type_of_title == 'receita'
                    }
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="Não Corrente"
                    disabled={
                      (this.state.title &&
                        this.state.current_or_not_current &&
                        !this.state.edicao) ||
                      this.state.type_of_title == 'receita'
                    }
                  />
                </RadioGroup>
              </FormControl>

              {/* {(this.state.payment_launch === 'true') &&
                        <FormControl fullWidth={true}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={32}
                            >
                                <Grid item sm={6}>
                                    <InputLabel
                                        htmlFor="payment_mode">{this.state.type_of_title === 'receita' ? 'Forma de Recebimento' : 'Forma de pagamento'}</InputLabel>
                                    <Select
                                        fullWidth
                                        style={{minWidth: "200px"}}
                                        value={this.state.payment_mode}
                                        inputProps={{
                                            name: 'payment_mode',
                                            id: 'payment_mode',
                                        }}
                                        onChange={this.handleChange}
                                        error={this.state.payment_modeErro}
                                    >
                                        {this.state.paymentModeList.map((item, index) => (
                                            <MenuItem value={item.forma_pagamento} key={index}>{item.forma_pagamento}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        label={"Nº do cheque"}
                                        value={this.state.check_number}
                                        inputProps={{
                                            maxLength: '20'
                                        }}
                                        name={"check_number"}
                                        style={{visibility: (this.state.payment_mode === 'Cheque') ? "visible" : "hidden"}}
                                        onChange={this.handleChange}
                                        error={this.state.check_numberErro}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                        }

                        {(this.state.payment_launch === 'true' && this.state.payment_mode !== "" && this.state.payment_mode !== 'Dinheiro') &&
                        <FormControl fullWidth={true}>
                            <AutoSelect
                                value={this.state.pgtoRecebBank}
                                initialValue={this.state.pgtoRecebBank}
                                suggestions={this.state.bankList}
                                handleChange2={this.BancoDoPagamentoHandleChange}
                                placeholder='Banco do Pgto/Receb.'
                                error={this.state.pgtoRecebBankErro}
                            />
                        </FormControl>
                        } */}

              {this.state.title_issuer === 'fornecedor' &&
                this.state.type_of_title === 'despesa' &&
                !this.state.account_group.includes('2.3') && (
                  <FormControl
                    fullWidth={true}
                    style={{ alignItems: 'center' }}
                  >
                    <InputLabel htmlFor="avaliacao">
                      Avalie o Serviço:
                    </InputLabel>
                    <br></br>
                    <StarRating
                      initialRating={this.state.avaliacao}
                      handleOnClick={(value) =>
                        this.setState({ avaliacao: value })
                      }
                      emptyColor={'#9ea6d8'}
                      size={'20px'}
                    />
                  </FormControl>
                )}
            </Grid>
          </Grid>

          <Grid
            style={{ marginTop: '2em' }}
            container={true}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              color="primary"
              variant="contained"
              // Fechar modal quando adicionar tb
              onClick={this.validaForm}
            >
              Salvar
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Form;
