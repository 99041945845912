import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReactTable from 'react-table';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import ReactExport from 'react-data-export';
import InputLabel from '@material-ui/core/InputLabel';
import Form from './Form';
import AppState from '../../../state';
import { Utils } from '../../../services';
import { browserHistory } from 'react-router';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditICon from '@material-ui/icons/Edit';
import Modal from '../../../components/lib/Modal';
import debounce from 'lodash.debounce';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  halfGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto',
  },
};

class Titulos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      dialog: false,
      period: '5dias',
      titulosList: [],
      backupTitulosList: [],
      dtInicio: '',
      dtFim: '',
      statusFilter: 'Todos',
      periodType: 'dt_lancamento',
      projectList: [],
    };

    this.getTableData();
    this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
  }

  _debouncedHandleChange = () => {
    this.getTableData();
  };

  hasDeletePermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const lancamentoView = permissions.views
      .filter((view) => view.id_view === 'lancamento_titulo')
      .pop();

    const hasDeletePermission = lancamentoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'delete_titulo';
      },
      false
    );

    return hasDeletePermission;
  };

  toggle = () => {
    this.setState({
      isSelected: !this.state.isSelected,
    });
  };

  hasEditPermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const lancamentoView = permissions.views
      .filter((view) => view.id_view === 'lancamento_titulo')
      .pop();

    const hasEditPermission = lancamentoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'edit_titulo';
      },
      false
    );

    return hasEditPermission;
  };

  deleteItemOnClick = (id_titulo, id_pgto_receb = null) => {
    AppState.isLoading = true;
    const data = JSON.stringify({
      id_titulo: id_titulo,
      id_pgto_receb: id_pgto_receb,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/DeleteTitulo.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        return response;
      })
      .then((response) => {
        alert('Lançamento removido com sucesso');
        this.getTableData();
        this.toggle();
        this.setState({
          dialogConfirmationDelete: false,
          selectedTitulo: '',
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos. Recarregue a página e tente novamente');
          } else if (ret == '40002') {
            alert('Título pago não pode ser excluído');
          } else if (ret == '40003') {
            alert(
              'Titulo não encontrado. Obs: Título de mês anterior não pode ser excluído.'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  periodHandleChange = (event) => {
    this.setState(
      {
        dtInicio: '',
        dtFim: '',
        period: event.target.value,
      },
      () => {
        if (this.state.period != 'custom') {
          this.getTableData();
        }
      }
    );
  };

  statusHandleChange = (event) => {
    let status = event.target.value;
    this.setState({
      titulosList: this.state.backupTitulosList.filter((titulo) =>
        this.filterTableData(titulo, status)
      ),
      statusFilter: status,
    });
  };

  periodTypeHandleChange = (event) => {
    this.setState(
      {
        periodType: event.target.value,
      },
      () => {
        this.getTableData();
      }
    );
  };

  filterTableData(titulo, status = null) {
    status = status == null ? this.state.statusFilter : status;
    if (status == 'Em aberto') {
      return titulo.status == 'Em Aprovação' || titulo.status == 'Aprovado'
        ? true
        : false;
    } else if (status == 'Em Aprovação') {
      return titulo.status == 'Em Aprovação' ? true : false;
    } else if (status == 'Aprovado') {
      return titulo.status == 'Aprovado' ? true : false;
    } else if (status == 'Pago') {
      return titulo.status == 'Pago' ? true : false;
    } else if (status == 'Reprovado') {
      return titulo.status == 'Reprovado' ? true : false;
    }
    return true;
  }

  preparaDado = (dados) => {
    if (dados == null || dados.length < 1) return [];
    let ret = dados.slice(0);
    for (let i = 0; i < dados.length; i++) {
      ret[i].dt_vencimento = Utils.dateToPT(dados[i].dt_vencimento);
      ret[i].dt_lancamento = Utils.dateToPT(dados[i].dt_lancamento);
      ret[i].valor = Utils.float2moeda(dados[i].valor);
      ret[i].custo_indireto = dados[i].custo_indireto === 'f' ? 'Não' : 'Sim';
      ret[i].eh_corrente = dados[i].eh_corrente === 'f' ? 'Não' : 'Sim';
    }

    return ret;
  };

  getTableData = () => {
    AppState.isLoading = true;

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetTitulos.php?json={"periodo":"${this.state.period}", "tipo_data":"${this.state.periodType}", "dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}"}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        const data = response.request.filter((titulo) =>
          this.filterTableData(titulo)
        );
        const uniqueProjects = Array.from(
          new Set(data.map((el) => el.nome_display))
        ).sort();
        this.setState(
          {
            titulosList: data,
            backupTitulosList: response.request,
            projectList: uniqueProjects.map((el) => ({ label: el, value: el })),
          },
          () => (AppState.isLoading = false)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002') {
            console.log('Dados invalidos/não informados');
          }

          if (ret == '40003') {
            console.log('Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.setState({ dialog: false, edicao: false }, () => this.getTableData());
  };

  openDialog = () => {
    this.setState({
      edicao: false,
      dialog: true,
    });
  };

  dateHandleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        if (this.state.dtInicio.length === 10 && this.state.dtFim.length === 10)
          this._debouncedHandleChange();
      }
    );
  };

  openDialogEdicao = (idTitulo) => {
    this.setState({
      edicao: true,
      dialog: true,
      titulo: idTitulo,
    });
  };

  render() {
    const hasDeletePermission = this.hasDeletePermission();
    const hasEditPermission = this.hasEditPermission();
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Lançamento de Títulos</h1>

        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          style={{
            marginTop: '16px',
            marginBottom: '8px',
            display: 'flex',
            gap: '16px',
          }}
        >
          <Grid item sm lg={8}>
            <Grid container spacing={16}>
              <Grid item>
                <FormControl component="fieldset" fullWidth>
                  <InputLabel htmlFor={'period'}> Tipo de Filtro </InputLabel>
                  <Select
                    inputProps={{ id: 'period', name: 'period' }}
                    style={{ margin: '0.5em 0px' }}
                    value={this.state.periodType}
                    onChange={this.periodTypeHandleChange}
                  >
                    <MenuItem value={'dt_lancamento'}>Lançamento</MenuItem>
                    <MenuItem value={'dt_vencimento'}>Vencimento</MenuItem>
                  </Select>
                </FormControl>
                <FormControl component="fieldset" fullWidth>
                  <InputLabel htmlFor={'period'}>
                    {' '}
                    Período de{' '}
                    {this.state.periodType == 'dt_lancamento'
                      ? 'Lançamento'
                      : 'Vencimento'}{' '}
                  </InputLabel>
                  <Select
                    inputProps={{ id: 'period', name: 'period' }}
                    style={{ margin: '0.5em 0px' }}
                    value={this.state.period || 'today'}
                    onChange={this.periodHandleChange}
                  >
                    <MenuItem value={'Hoje'}>Hoje</MenuItem>
                    <MenuItem value={'Ontem'}>Ontem</MenuItem>
                    <MenuItem value={'5dias'}>Últimos 5 dias</MenuItem>
                    <MenuItem value={'EsseMes'}>Esse Mês</MenuItem>
                    <MenuItem value={'30dias'}>Últimos 30 dias</MenuItem>
                    <MenuItem value={'60dias'}>Últimos 60 dias</MenuItem>
                    <MenuItem value={'90dias'}>Últimos 90 dias</MenuItem>
                    <MenuItem value={'p5dias'}>Próximos 5 dias</MenuItem>
                    <MenuItem value={'custom'}>Customizado</MenuItem>
                  </Select>
                </FormControl>
                <FormControl component="fieldset" fullWidth>
                  <InputLabel htmlFor={'period'}> Status </InputLabel>
                  <Select
                    inputProps={{ id: 'status', name: 'status' }}
                    style={{ margin: '0.5em 0px' }}
                    value={this.state.statusFilter}
                    onChange={this.statusHandleChange}
                  >
                    <MenuItem value={'Todos'}>Todos</MenuItem>
                    <MenuItem value={'Em aberto'}>Em aberto</MenuItem>
                    <MenuItem value={'Em Aprovação'}>Em Aprovação</MenuItem>
                    <MenuItem value={'Aprovado'}>Aprovado</MenuItem>
                    <MenuItem value={'Pago'}>Pago</MenuItem>
                    <MenuItem value={'Reprovado'}>Reprovado</MenuItem>
                  </Select>
                </FormControl>
                <div style={styles.halfGrid}>
                  {this.state.period === 'custom' && (
                    <>
                      <TextField
                        label="Inicio"
                        type="date"
                        defaultValue={this.state.dt_inicio}
                        onChange={this.dateHandleChange('dtInicio')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                      <TextField
                        label="Fim"
                        type="date"
                        defaultValue={this.state.dt_fim}
                        onChange={this.dateHandleChange('dtFim')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container justify="flex-end" alignItems="center">
              <Grid
                item
                style={{
                  display: 'flex',
                  gap: '24px',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  padding: '16px 0',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.openDialog()}
                >
                  <AddIcon />
                  Novo Título
                </Button>
                <ExcelFile
                  filename={'RelatorioTitulos-' + Utils.getTodayDate()}
                  element={
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.exportarExcel}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="24"
                        height="24"
                        viewBox="0 0 192 192"
                        style={{ fill: '#000000', paddingRight: '6px' }}
                      >
                        <g
                          fill="none"
                          fillRule="nonzero"
                          stroke="none"
                          strokeWidth="1"
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          strokeMiterlimit="10"
                          strokeDasharray=""
                          strokeDashoffset="0"
                          fontFamily="none"
                          fontWeight="none"
                          fontSize="none"
                          textAnchor="none"
                          style={{ mixBlendMode: 'normal' }}
                        >
                          <path d="M0,192v-192h192v192z" fill="none"></path>
                          <g fill="#ffffff">
                            <g id="surface1">
                              <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      Exportar
                    </Button>
                  }
                >
                  <ExcelSheet
                    data={() =>
                      this.preparaDado(
                        this.selectTable.getResolvedState().sortedData
                      )
                    }
                    name="Titulos"
                  >
                    <ExcelColumn
                      label="Nº titulo"
                      value="id_titulo"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Status"
                      value="status"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="CPJ/CNPJ Contraparte"
                      value="cpf_cnpj_contraparte"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Tipo Contraparte"
                      value="tipo_contraparte"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Nome Contraparte"
                      value="nome_contraparte"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="É Corrente?"
                      value="eh_corrente"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Natureza"
                      value="natureza"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Conta"
                      value="id_conta"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Desc. Conta"
                      value="desc_conta"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Projeto"
                      value="nome_display"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Descrição"
                      value="descricao"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Valor"
                      value="valor"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Data Lançamento"
                      value="dt_lancamento"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Data Vencimento"
                      value="dt_vencimento"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Forma Pagamento"
                      value="forma_pagamento"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="N Cheque"
                      value="n_cheque"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="N Documento"
                      value="num_documento"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Nome Contraparte Outro"
                      value="nome_contraparte_outro"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Banco"
                      value="banco_titulo"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Código Contrato"
                      value="id_contrato"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                    <ExcelColumn
                      label="Custo Indireto"
                      value="custo_indireto"
                      style={{ font: { sz: '24', bold: true } }}
                    />
                  </ExcelSheet>
                </ExcelFile>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ReactTable
          filterable={true}
          data={this.state.titulosList}
          defaultPageSize={20}
          ref={(r) => {
            this.selectTable = r;
          }}
          className="-striped -highlight"
          defaultFilterMethod={(filter, row) =>
            filter.id === 'dt_lancamento' || filter.id === 'dt_vencimento'
              ? Utils.dateToPT(String(row[filter.id])).startsWith(
                  filter.value.toLowerCase()
                )
              : String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: 'Títulos',
              columns: [
                {
                  Header: 'Nº',
                  accessor: 'id_titulo',
                  width: 60,
                },
                // {
                //   Header: "Status",
                //   accessor: "status",
                //   width: 110
                // },
                {
                  Header: 'Natureza',
                  accessor: 'natureza',
                  width: 75,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                        textTransform: 'capitalize',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Conta',
                  accessor: 'id_conta',
                  width: 50,
                },
                {
                  Header: 'Desc. Conta',
                  accessor: 'desc_conta',
                },
                {
                  Header: 'Tipo Contraparte',
                  accessor: 'tipo_contraparte',
                  width: 100,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                        textTransform:
                          row.value == 'pj' ? 'uppercase' : 'capitalize',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Nome Contraparte',
                  accessor: 'nome_contraparte',
                },
                {
                  Header: 'Projeto',
                  accessor: 'nome_display',
                  width: 144,
                  filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                      return true;
                    }
                    if (filter.value === 'allButZero') {
                      return row[filter.id] !== '0 - AmbientalConsult';
                    }
                    return row[filter.id] === filter.value;
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Todos</option>
                      <option value="allButZero">Todos Menos 0</option>
                      {this.state.projectList.map((el) => {
                        return <option value={el.value}>{el.label}</option>;
                      })}
                    </select>
                  ),
                  Cell: (row) => <div>{row.value}</div>,
                },
                {
                  Header: 'Descrição',
                  accessor: 'descricao',
                },
                {
                  Header: 'Valor',
                  accessor: 'valor',
                  width: 110,
                  Footer: (row) => (
                    <span>
                      {
                        // Get the total of the price
                        'R$ ' +
                          Utils.float2moeda(
                            row.data.reduce(
                              (total, { valor }) =>
                                (total += parseFloat(valor)),
                              0
                            )
                          )
                      }
                    </span>
                  ),
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {'R$ ' + Utils.float2moeda(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Lançamento',
                  accessor: 'dt_lancamento',
                  width: 90,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Vencimento',
                  accessor: 'dt_vencimento',
                  width: 90,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Ação',
                  accessor: 'acao',
                  filterable: false,
                  width: 100,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="baseline"
                      >
                        {hasDeletePermission ? (
                          <DeleteIcon
                            style={{ cursor: 'pointer' }}
                            onClick={(event) =>
                              row.original['status'] == 'Em Aberto'
                                ? this.deleteItemOnClick(
                                    row.original['id_titulo']
                                  )
                                : this.setState({
                                    selectedTitulo: row['original'],
                                    dialogConfirmationDelete: true,
                                  })
                            }
                          />
                        ) : (
                          ''
                        )}
                        {hasEditPermission ? (
                          <EditICon
                            onClick={() =>
                              this.openDialogEdicao(row.original['id_titulo'])
                            }
                            style={{
                              cursor: 'pointer',
                              width: '22px',
                              height: '22px',
                              minWidth: '22px',
                              minHeight: '22px',
                              marginLeft: '5px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
        {this.state.dialogConfirmationDelete && (
          <Modal
            titulo="Confirmação"
            onCloseButton={() =>
              this.setState({
                dialogConfirmationDelete: false,
                selectedTitulo: '',
              })
            }
            isOpen={this.state.dialogConfirmationDelete}
          >
            <Grid container spacing={32}>
              <Grid item md={10}>
                Tem certeza que deseja apagar o titulo de R${' '}
                {Utils.float2moeda(this.state.selectedTitulo.valor)} lançado na
                data {Utils.dateToPT(this.state.selectedTitulo.dt_lancamento)}{' '}
                para o projeto {this.state.selectedTitulo.id_projeto}?<br></br>
                <br></br>
                Atenção! O Pagamento / Recebimento associado a este titulo
                também será apagado.
              </Grid>
              <Grid
                container
                item
                md={10}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button
                  onClick={() =>
                    this.deleteItemOnClick(
                      this.state.selectedTitulo.id_titulo,
                      this.state.selectedTitulo.id_pgto_receb
                    )
                  }
                  color="primary"
                  variant="contained"
                >
                  Deletar
                </Button>
              </Grid>
            </Grid>
          </Modal>
        )}
        <Form
          open={this.state.dialog}
          close={this.closeDialog}
          titulo={this.state.titulo}
          edicao={this.state.edicao}
          isLoading={true}
        />
      </div>
    );
  }
}

export default Titulos;
